import browser from "browser-detect";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import GameCards from "../Components/Games/GameCards";
import { getDeviceIP } from "../Components/Common/getIp";
import MobileNavbar from "../Components/MobileNavbar";
import BasicLayout from "../BasicLayout";
import { replaceGamesKeysNames } from "../utils/helper";
import { getItemFromLocalStorage } from "../utils/localStorage";
import Banner from "../Components/Banner";
import { games } from "../modules/Games/actions";
import PageLoading from "../Components/PageLoading";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const browserInfo = browser();

const GamesPage = (props) => {
  const { t } = useTranslation();
	const location = useLocation();
  const { pathname } = location;

  const { games, gamesData, loading } = props;
  let allGames = gamesData?.Games;

  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: 100,
      WithWidget: false,
      CategoryId: 5,
      ProviderIds: null,
      IsForMobile: false,
      Name: "",
      LanguageId: LanguageId,
    };
    await games(payload);
  };
  useEffect(() => {
    getGamesApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let pageName = pathname.split("/")[1]
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetGames",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
		// eslint-disable-next-line
  }, []);

  return (
    <BasicLayout>
      <div className="casino-page games">
        <div className="casino-header">
          <div>
            <MobileNavbar />
            <div className="casino-header-container">
              <Banner />
            </div>
          </div>
        </div>
        <div className="uw-content content-px">
          <div className="">
            {loading && (
              <PageLoading />
            )}
            {!loading && allGames && allGames?.length > 0 && (
              <div
                className="casino-games-grid"
                id="infinite-list"
                style={{ overflow: "auto" }}
              >
                {allGames?.map((game, i) => {
                  let result = replaceGamesKeysNames(game);
                  return (
                    <div key={result.name}>
                      <GameCards isGamesPage={true} data={result} />
                    </div>
                  );
                })}
              </div>
            )}
            {!loading && allGames && allGames?.length < 1 && (
              <p className="no-results t-center">{t("No games found")}</p>
            )}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.gamesReducer?.gamesData,
    loading: state?.gamesReducer?.loading,
  };
};

export default connect(mapStateToProps, {
  games,
})(GamesPage);
