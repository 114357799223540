import BasicLayout from "../BasicLayout";
import DepositSlider from "../Components/Deposit/DepositSlider";
import DepositWalletItem from "../Components/Deposit/DepositWalletItem";
import EthIcon from "../Components/Deposit/EthIcon";
import TronIcon from "../Components/Deposit/TronIcon";
import CabinetNav from "../Components/History/CabinetNav";
import { useTranslation } from "react-i18next";
import Icon from "../Components/global/Icon";

const walletData = [
  {
    network: "Ethereum Blockchain",
    currencies: "USDT ERC, USDC, ETH",
    icon: <EthIcon />,
    address: "0xB96b2077908D661B8a5Bc754e9054027ee78e56c",
  },
  {
    network: "Tron Blockchain ",
    currencies: "USDT TRC",
    icon: <TronIcon />,
    address: "TV9FbzGW9kNBKc7BuWxgWxJRKidSmoByDt",
  },
];

export default function DepositPage() {
  const { t } = useTranslation();
  return (
    <BasicLayout>
      <div className="uw-content content-px content-py">
        <CabinetNav />
        <div className="cabinet-block deposit">
          <div className="deposit__heading">
            <div className="deposit__heading-icon">
              <Icon id="deposit" />
            </div>
            <p className="deposit__heading-text">{t("deposit_accept_only")}</p>
          </div>
          <div className="cabinet-divider"></div>
          <div className="deposit__container">
            <div className="deposit__block">
              <p className="deposit__heading-text">{t("deposit_contact_before")}</p>
              <div className="deposit__wallets">
                {walletData.map((x) => {
                  return (
                    <DepositWalletItem
                      key={x.network}
                      data={x}
                    />
                  );
                })}
              </div>
              <p className="deposit__withdrawText">{t("deposit_withdraw_message")}</p>
            </div>
            <DepositSlider />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
