import browser from "browser-detect";
import { useEffect } from "react";
import { saveActivity } from "../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../Common/getIp";
// import MobileNavbar from "../MobileNavbar/MobileNavbar";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { liveCasino } from "../../constant";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../global/Icon";
import { useTranslation } from "react-i18next";

const browserInfo = browser();
export default function LiveCasinoSection() {
	const location = useLocation();
	const { t } = useTranslation();
  const { pathname } = location;
  useEffect(() => {
    let pageName = pathname.split("/")[1]
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetProductUrl",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  }, [pathname]);
  

  return (
    <div className="liveCasino content-px">
      <div className="">
        <div className="liveCasino-grid">
					{liveCasino.map(live => {
						return (
							<NavLink type="button" to={`/live-casino/${live.slug}`} className="liveCasino-card" key={live.title}>
								<div className="liveCasino-img">
									<img loading="lazy" src={live.img} alt={live.title} width="539" height="836" />
								</div>
								<img className={`liveCasino-logo ${live.class}`} src={live.logo} alt="" />
								<div className="liveCasino-play">
									<Icon id="play" />
									{t("play")}
								</div>
							</NavLink>
						)
					})}
				</div>
      </div>
    </div>
  );
};
