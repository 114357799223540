import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { sidebarRoutes } from "../constant";
import Icon from "./global/Icon";

const MobileNavbar = () => {
	const { t } = useTranslation();
  return (
    <div className="">
      <div className="content-px casino-navT casino-scroll-horizontal">
        {sidebarRoutes.map((item) => (
					<NavLink key={item.name} to={item.url} className="sidebar-link">
						<div className="sidebar-link-icon">
							<Icon id={item.icon} />
						</div>
						<span>{t(item.name)}</span>
					</NavLink>
        ))}
      </div>
      <div className="casino-divider casino-divider-center mb"></div>
		</div>
  );
};

export default MobileNavbar;
