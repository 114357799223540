import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import CloseDialogBtn from "../global/CloseDialogBtn";
const CasinoCardInfoModel = (props) => {
  const { data, handlePlaybtn, handledemobtn } = props;
  const { gameImage, name, hasDemo, providerName, jackpotValue } = data;
	const { t } = useTranslation();

  return (
		<Dialog 
			className="theme-modal casinoInfoModal" 
			onClose={() => props.setinfoModal(false)} 
			open={props.infoModal}
		>
      <div className="theme-modal-body">
				<div className="theme-modal-heading">
					<p className="theme-modal-title">{name}</p>
					<CloseDialogBtn onClose={() => props.setinfoModal(false)} />
				</div>
        <div className="casinoInfoModal-row">
          <div className="casinoInfoModal-block">
            <div className="casinoInfoModal-img">
              <img src={gameImage} width="" height="" alt={name} />
            </div>
            <div>
              <button className="theme-btn full-width mb" onClick={handlePlaybtn}>{t("Play Now")}</button>
							{hasDemo && (<button onClick={handledemobtn} className="theme-btn-outlined full-width" >{t("Demo")}</button>)}
            </div>
          </div>
          <div className="casinoInfoModal-block">
            <p className="casinoInfoModal-title">{t("Features")}</p>
            <p className="casinoInfoModal-text">
              {t("Provider Name")}: <span>{providerName}</span>
            </p>
            <p className="casinoInfoModal-text">
              {t("Jackpot Value")}:{" "}
              <span>{jackpotValue === "" ? 0 : jackpotValue}</span>
            </p>
          </div>
        </div>
      </div>
		</Dialog>
    // <Modal
    //   open={props.infoModal}
    //   footer={null}
    //   width="700px"
    //   className="theme-modal casinoInfoModal"
    //   onCancel={() => props.setinfoModal(false)}
    // >
      
    // </Modal>
  );
};

export default CasinoCardInfoModel;
