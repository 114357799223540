import axios from "axios";
import { apiVars } from "../../../constant";
class HeaderApi {
  getClientBalance = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/GetClientBalance`,
      data
    );
  getGames = (data) =>
    axios.post(`${apiVars.baseApiUrl}/Main/GetGames`, data);
}

const api = new HeaderApi();
export default api;
