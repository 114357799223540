
import { LoginActions } from "../types";

const initialState = {
  loginData: [],
  isloggedIn: false,
  closeModal: false,
  getClientByToken: false,
  getClientByTokenData: [],
  modal: false,
  signupData: [],
  allCountries: [],
  allCities: [],
  registerStatusModal: false,
  recoveryData: [],
	userData: null
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
		case LoginActions.SET_USER_DATA: {
			return {
        ...state,
        userData: action.payload,
      };
		}
    case LoginActions.LOGIN: {
      return {
        ...state,
        isloggedIn: false,
        loginData: action.payload,
      };
    }
    case LoginActions.LOGIN_SUCCESS: {
      const { ResponseCode } = action?.payload?.data;
      return {
        ...state,
        isloggedIn: true,
        loginData: action.payload,
        closeModal: ResponseCode === 0 ? true : false,
      };
    }
    case LoginActions.LOGIN_ERROR: {
      return {
        ...state,
        isloggedIn: false,
        loginData: action.payload,
      };
    }
    case LoginActions.SIGNUP: {
      return {
        ...state,
        signupData: action.payload,
      };
    }
    case LoginActions.SIGNUP_SUCCESS: {
      return {
        ...state,
        signupData: action.payload,
        registerStatusModal: false,
        modal: true,
      };
    }
    case LoginActions.SIGNUP_ERROR: {
      return {
        ...state,
        signupData: action.payload,
      };
    }
    case LoginActions.GET_CLIENT_BY_TOKEN: {
      return {
        ...state,
        getClientByToken: false,
        getClientByTokenData: action.payload,
      };
    }
    case LoginActions.GET_CLIENT_BY_TOKEN_SUCCESS: {
      return {
        ...state,
        getClientByToken: true,
        getClientByTokenData: action.payload,
      };
    }
    case LoginActions.GET_CLIENT_BY_TOKEN_ERROR: {
      return {
        ...state,
        getClientByToken: false,
        getClientByTokenData: action.payload,
      };
    }
    case LoginActions.LOGIN_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }
    case LoginActions.LOGIN_MODAL_SUCCESS: {
      return {
        ...state,
        registerStatusModal: false,
        modal: action.payload,
      };
    }
    case LoginActions.LOGIN_MODAL_ERROR: {
      return {
        ...state,
        modal: action.payload,
      };
    }

    case LoginActions.REGISTER_MODAL: {
      return {
        ...state,
        registerStatusModal: action.payload,
      };
    }
    case LoginActions.REGISTER_MODAL_SUCCESS: {
      return {
        ...state,
        registerStatusModal: action.payload,
      };
    }
    case LoginActions.REGISTER_MODAL_ERROR: {
      return {
        ...state,
        registerStatusModal: action.payload,
      };
    }

    case LoginActions.LOGOUT: {
      return {
        ...state,
        isloggedIn: action.payload,
      };
    }
    case LoginActions.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        allCountries: action.payload,
      };
    }
    case LoginActions.GET_COUNTRIES_ERROR: {
      return {
        ...state,
        allCountries: action.payload,
      };
    }

    case LoginActions.GET_CITIES_SUCCESS: {
      return {
        ...state,
        allCities: action.payload,
      };
    }
    case LoginActions.GET_CITIES_ERROR: {
      return {
        ...state,
        allCities: action.payload,
      };
    }

    case LoginActions.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        recoveryData: action.payload.data,
      };
    }
    case LoginActions.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        recoveryData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default LoginReducer;
