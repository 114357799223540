import axios from "axios";
import { apiVars } from "../../../../constant";
class HomeApi {
  getGames = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/GetProductUrl`,
      data
    );
}

const api = new HomeApi();
export default api;
