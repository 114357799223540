import { Dialog } from "@mui/material";
import React from 'react'
import moment from 'moment'
import CloseDialogBtn from '../global/CloseDialogBtn';

const BonusInfoModal = (props) => {
	const { infomodal, data, setInfomodal } = props

	return (
		<Dialog
			open={infomodal}
			className="theme-modal"
			onClose={() => setInfomodal(false)}
		>
			<div className="theme-modal-body">
				<div className="theme-modal-heading">
					<p className="theme-modal-title">Bonus Info</p>
					<CloseDialogBtn onClose={() => setInfomodal(false)} />
				</div>
				<div>
					<p className="casinoInfoModal-text">ID: <span>{data?.Bonus?.Id} </span></p>
					<p className="casinoInfoModal-text">Name: <span>{data?.Bonus?.Name} </span></p>
					<p className="casinoInfoModal-text">Turnover left: <span>{data?.Bonus?.TurnoverAmountLeft} </span></p>
					<br/>
					<p className="casinoInfoModal-title">Triggers</p>
					<p className="casinoInfoModal-text">Name: <span>{data?.Triggers[0]?.Name}</span></p>
					<p className="casinoInfoModal-text">Type: <span>{data?.Triggers[0]?.TypeName}</span></p>
					<p className="casinoInfoModal-text">Percent: <span>{data?.Triggers[0]?.Percent}</span></p>
					<p className="casinoInfoModal-text">Start Time: <span>{moment(data?.Triggers[0]?.StartTime).format("LLL")}</span></p>
					<p className="casinoInfoModal-text">Finish Time: <span>{moment(data?.Triggers[0]?.FinishTime).format("LLL")}</span></p>
					<p className="casinoInfoModal-text">Status: <span>{data?.Triggers[0]?.StatusName}</span></p>
				</div>
			</div>
		</Dialog>
	)
}

export default BonusInfoModal