import axios from "axios";
import { apiVars } from "../../../../constant";

class CasherApi {
    casherWithdraw = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data);
    casherDeposit = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data);
    newTicket = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data);
}

const api = new CasherApi();
export default api;