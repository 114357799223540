import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function BetFilters(props) {
	const { setbetSelectValue, betSelectValue, selectedBetStatus, selectedBetProducts } = props;
	const { t } = useTranslation();

  const handleDateChange = (e) => {
		const { target: { value } } = e;
		let period;
		switch(value) {
			case '24 hours':
				period = 1
				break;
			case '1 month':
				period = 30
				break;
			default:
				period = Number(value.split(" ")[0])
				break
		}
    setbetSelectValue({
      ...betSelectValue,
      time: value,
      date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
        "YYYY-MM-DD hh:mm"
      ),
      fromDate: moment(
        new Date(Date.now() - period * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD hh:mm"),
    });
  };
  return (
    <div className="cabinet-subNav-history-selects">
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Period-of-time")}</p>
				<Select
          defaultValue={betSelectValue.time}
          onChange={handleDateChange}
					MenuProps={{
						className: "custom-select-dropdown",
					}}
        >
					<MenuItem value={"24 hours"}>{t("24 hours")}</MenuItem>
					<MenuItem value={"3 days"}>{t("3 days")}</MenuItem>
					<MenuItem value={"7 days"}>{t("7 days")}</MenuItem>
					<MenuItem value={"1 month"}>{t("1 month")}</MenuItem>
        </Select>
      </div>
      <div className="inputGroup">
				<p className="inputGroup-label">{t("Status")}</p>
				<Select
          defaultValue={betSelectValue.status}
          onChange={(e) => setbetSelectValue({ ...betSelectValue, status: e.target.value })}
					MenuProps={{
						className: "custom-select-dropdown",
					}}
			 	>
					<MenuItem value="all">{t("All")}</MenuItem>
					{selectedBetStatus && selectedBetStatus.map((e, i) => {
							return (
								<MenuItem value={e.Value} key={e.Name}>{t(e.Name)}</MenuItem>
							);
						})
					}
        </Select>
      </div>
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Products")}</p>
				<Select
					displayEmpty
					MenuProps={{
						className: "custom-select-dropdown",
					}}
          onChange={(e) => setbetSelectValue({ ...betSelectValue, product: e.target.value })}
					renderValue={(selected) => {
            if (!selected) {
              return t("Select product");
            }
          }}
				>
					<MenuItem disabled value="">{t("Select product")}</MenuItem>
					{selectedBetProducts && selectedBetProducts.map((e, i) => {
						return (
							<MenuItem value={e.Id} key={e.Id}>{t(e.Name)}</MenuItem>
						)})
					}
        </Select>
      </div>
    </div>
  );
};

