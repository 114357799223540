import { Images } from "../../Images";
import { useTranslation } from "react-i18next";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import Icon from "../global/Icon";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../modules/Login/actions";
import { cabinetNav } from "../../constant";
import ProfileIcon from "../icons/ProfileIcon";

export default function NavbarProfileSidebar(props) {
  const { closeHandler, isSidebar, user, userbalance } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const email = getItemFromLocalStorage("Email");

  function handleLogout() {
    dispatch(logout(false));
  }
  //style={{backgroundImage: `url(${Images.textureBackground})`}}
  return (
    <div className={`${isSidebar ? "active" : ""} sidebarM on-tablet`}>
      <div>
        <div className="sidebarM-header">
          <div className="sidebarM-header-logo">
            <Icon id="mobileLogo" />
          </div>
          <button
            onClick={closeHandler}
            className="sidebarM-close">
            <Icon id="close" />
          </button>
        </div>
        <div className="sidebarM-content">
          <div className="sidebarM-user">
            <ProfileIcon />
            {/* <img className="sidebarM-user-img" width="60"  height="60" src={Images.userIcon} alt="user icon" loading="lazy" /> */}
            <div className="overflow-hidden">
              <p className="sidebarM-user-email">{email}</p>
              <p className="sidebarM-user-balance">
                {user.currency} {userbalance || 0}
              </p>
            </div>
          </div>
          <div className="sidebarM-links">
            {cabinetNav.map((x) => {
              return (
                <NavLink
                  key={x.route}
                  to={x.route}
                  className="sidebarM-link">
                  <div className="sidebarM-link-row">
                    <Icon id={x.icon} />
                    {x.key}
                  </div>
                  <img
                    width="16"
                    height="16"
                    src={Images.chevronRightIcon}
                    alt="chevron right icon"
                    className="sidebarM-link-chevron"
                  />
                </NavLink>
              );
            })}
            <button
              type="button"
              onClick={handleLogout}
              className="sidebarM-link sidebarM-logout">
              <div className="sidebarM-link-row">
                <Icon id="logout" />
                {t("Logout")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
