import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Images } from "../../Images";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { verificationList } from "../../modules/Casher/lib/constants";
import {
  documentType, getdocumentHistory, submitDocument
} from "../../modules/Casher/Profile/Services/actions";
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { apiVars } from "../../constant";
import CustomAlert from "../global/CustomAlert";

const Verification = (props) => {
  const { chooseFile } = Images;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const hiddenFileInput = useRef(null);
  const [Base64, setBase64] = useState();
  const [ImageName, setImageName] = useState();
  const [ImageType, setImageType] = useState();
  const [DocumentTypeId, setDocumentTypeId] = useState();
  const { documentType, submitDocument, getdocumentHistory } = props;
  const DocumentType = useSelector(
    (state) => state?.profileReducer?.documentType?.data?.ResponseObject
  );
	const [error, setError] = useState("");
  const { t } = useTranslation();

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    getBase64(fileUploaded).then((res) => {
      let base64result = res.split(",")[1];
      const type = res.substring("data:image/".length, res.indexOf(";base64"));
      setImageType(type);
      setBase64(base64result);
      setImageName(fileUploaded.name);
    });
		setError("");
  };
  function handleClick() {
    hiddenFileInput.current.click();
  };

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const HandleKycDocumentsType = async () => {
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetKYCDocumentTypesEnum",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await documentType(payload);
  };

  const GetDocumentHistory = async () => {
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetClientIdentityModels",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getdocumentHistory(payload);
  };

  useEffect(() => {
    GetDocumentHistory();
    HandleKycDocumentsType();
    //eslint-disable-next-line
  }, []);

  const HandleSubmitDocument = async () => {
		if (!DocumentTypeId) {
			setError("Please, select document type")
			return;
		}
		if (!Base64) {
			setError("Please, upload your document file")
			return;
		}
		let payload = {
			Loader: true,
			PartnerId: apiVars.partnerId,
			TimeZone: apiVars.timeZone,
			LanguageId: LanguageId,
			ProductId: null,
			Method: "UploadImage",
			Controller: "Client",
			CategoryId: null,
			PageIndex: 0,
			PageSize: 100,
			ProviderIds: [],
			Index: null,
			ActivationKey: null,
			MobileNumber: null,
			Code: null,
			Name: ImageName,
			RequestData: JSON.stringify({
				ClientId: ClientId,
				Name: ImageName,
				ImageData: Base64,
				Extension: ImageType,
				DocumentTypeId: DocumentTypeId,
				Status: "",
			}),
			ClientId: ClientId,
			Token: Token,
		};
		await submitDocument(payload);
		setTimeout(() => {
			GetDocumentHistory();
		}, 2000);
	} 
	

  return (
    <div className="">
			<p className="cabinet-text">{t("DocumentIdentificationMessage")}</p>
			<ul className="profile-verif-list">
				{verificationList.map((list, i) => {
					return (
						<li key={i} className="cabinet-text">
							{t(list)}
						</li>
					);
				})}
			</ul>
			{error && <CustomAlert severity="error" text={error} />}
			<div className="profile-verif-action">
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Choose document")}</p>
					<Select
						onChange={(e) => {
							setDocumentTypeId(e.target.value);
							setError("");
						}}
						MenuProps={{
							className: "custom-select-dropdown", 
						}}
					>
						{DocumentType &&
              DocumentType?.map((e, i) => {
                return <MenuItem value={e.Id}>{t(e.Name)}</MenuItem>
              })}
					</Select>
				</div>
				<div className="profile-verif-upload">
					<button type="button" onClick={handleClick} className="theme-btn-outlined">
						<img width="16" height="16" className={themeMode === 'light' ? 'black-image' : ''} src={chooseFile} alt="upload icon" />
						&nbsp;&nbsp;&nbsp;
						<span>{t("SelectFile")}</span>
						<input
							type="file"
							ref={hiddenFileInput}
							onChange={handleChange}
							style={{ display: "none" }}
						/>
					</button>
					{ImageName && <p className="cabinet-text profile-verif-imgName">{ImageName}</p>}
				</div>
				<div className="d-flex">
					<button className="theme-btn" onClick={HandleSubmitDocument}>{t("Verify")}</button>
				</div>
			</div>
    </div>
  );
};

export default connect(null, {
  documentType,
  submitDocument,
  getdocumentHistory,
})(Verification);
