import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { Images } from "../../Images";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const Nav = ({
  navEl,
  url,
  setUrl,
  selectedCurrency,
	isSingleLink
}) => {
  // const { info } = Images;
  let ClientBalance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data
  );
  const { t } = useTranslation();

  let unused = ClientBalance?.Balances
    ? ClientBalance?.Balances[0]?.Balance
    : 0;
  let used = ClientBalance?.Balances ? ClientBalance?.Balances[1]?.Balance : 0;
  let clientBooking = 0;
	
	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
			))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#15191c',
			color: '#fff',
			maxWidth: 400,
			fontSize: theme.typography.pxToRem(14),
			border: '1px solid rgba(255, 255, 255, 0.12)',
			padding: '24px'
		},
	}));
	
	
  return (
    <>
      <div className="cabinet-subNav cabinet-subNav-components">
        <div className="cabinet-subNav-links">
          {navEl.map((el, i) => (
            <div data-e2e={t(el)} key={el} className={`${url === el || isSingleLink ? 'active' : ''} cabinet-subNav-link`} onClick={() => {setUrl(el);}}>
              {t(el)}
            </div>
          ))}
        </div>
        <div className="cabinet-subNav-info">
					<HtmlTooltip
						placement="bottom-end"
						title={
							<div className="info-tooltip">
								<div className="info-tooltip-item">
                  {t("Client Unused Balance")}: {unused} {selectedCurrency}
                </div>
                <div className="info-tooltip-item" data-e2e={`client-used-balance${used}`}>
                  {t("Client Used Balance")}: {used} {selectedCurrency}
                </div>
                <div className="info-tooltip-item">
                  {t("Client Booking")}: {clientBooking} {selectedCurrency}
                </div>
							</div>
						}
					>
						<div className="cabinet-subNav-info-content">
							<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
								<path fillRule="evenodd" clipRule="evenodd" d="M6.49972 0C2.91035 0 0 2.91035 0 6.49972C0 10.0891 2.91035 13 6.49972 13C10.0891 13 13 10.0891 13 6.49972C13 2.91035 10.0891 0 6.49972 0ZM7.85283 10.0737C7.51826 10.2058 7.25194 10.3059 7.05219 10.3752C6.85299 10.4446 6.62133 10.4792 6.35776 10.4792C5.95276 10.4792 5.63746 10.3802 5.41295 10.1826C5.18844 9.9851 5.07674 9.73473 5.07674 9.43043C5.07674 9.31213 5.08499 9.19107 5.1015 9.06781C5.11856 8.94455 5.14552 8.80588 5.18239 8.65016L5.60114 7.17105C5.63801 7.02908 5.66993 6.89426 5.69524 6.7688C5.72055 6.64224 5.73266 6.52614 5.73266 6.42049C5.73266 6.2323 5.69359 6.10023 5.616 6.02595C5.53731 5.95166 5.38929 5.91534 5.16863 5.91534C5.06078 5.91534 4.94963 5.9313 4.83572 5.96487C4.72292 5.99953 4.62497 6.0309 4.54463 6.06171L4.65524 5.60609C4.92927 5.49439 5.19175 5.39865 5.44212 5.31941C5.69249 5.23907 5.9291 5.19945 6.15196 5.19945C6.5542 5.19945 6.86455 5.2974 7.083 5.49109C7.30036 5.68533 7.40986 5.9379 7.40986 6.24825C7.40986 6.31263 7.40216 6.42599 7.3873 6.58777C7.37244 6.75009 7.34438 6.89812 7.30366 7.03403L6.88711 8.50874C6.85299 8.62705 6.82273 8.76241 6.79522 8.91374C6.76825 9.06506 6.75505 9.18061 6.75505 9.2582C6.75505 9.45409 6.79852 9.58781 6.88656 9.65879C6.9735 9.72978 7.12593 9.76554 7.34163 9.76554C7.44343 9.76554 7.55733 9.74739 7.6861 9.71217C7.81376 9.67695 7.9062 9.64559 7.96453 9.61862L7.85283 10.0737ZM7.0775 4.35865C7.35098 4.35865 7.58485 4.2684 7.77909 4.08792C7.97333 3.90743 8.07073 3.68842 8.07073 3.43145C8.07073 3.17558 7.97333 2.95547 7.77909 2.77333C7.5854 2.59065 7.35098 2.49985 7.0775 2.49985C6.80457 2.49985 6.56906 2.59065 6.37316 2.77333C6.17892 2.95547 6.07987 3.17558 6.07987 3.43145C6.07987 3.68787 6.17837 3.90743 6.37316 4.08792C6.56906 4.2684 6.80457 4.35865 7.0775 4.35865Z" fill="white"/>
							</svg>
							{/* <img width="14" height="14" loading="lazy" src={info} alt="info icon" /> */}
							<div className="cabinet-text">{t("User info")}</div>
						</div>
					</HtmlTooltip>
        </div>
      </div>
			<div className="cabinet-divider"></div>
    </>
  );
};

export default Nav;
