import browser from "browser-detect";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../Common/getIp";
import { getItemFromLocalStorage } from "../../../../utils/localStorage";
import { changePassword } from "../../../../modules/Casher/Services/actions";
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import CustomAlert from "../../../global/CustomAlert";


const browserInfo = browser();

const RightSettings = (props) => {
  const { changePassword } = props;
  const [password, setpassword] = useState({
    oldPwd: "",
    NewPwd: "",
    ConfirmPwd: "",
  });
  const [CheckPassword, setCheckPassword] = useState(true);
  const { t } = useTranslation();
  const ResponseCode = useSelector(
    (state) => state?.cashierReducer?.password?.data?.ResponseCode
  );
	
	const [showResponseError, setShowResonseError] = useState(false);
	
	const testPasswordRule = (v) => /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{8,})$/.test(v) || false;
	
	useEffect(() => {
		if (ResponseCode && ResponseCode !== 0) {
			setShowResonseError(true);
			setTimeout(() => {
				setShowResonseError(false);
			}, 3000)
		}
	}, [ResponseCode])
	

  useEffect(() => {
    if (password.NewPwd === password.ConfirmPwd) {
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
    //eslint-disable-next-line
  }, [password.ConfirmPwd]);

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const HandleChangePassword = async () => {
		if (!testPasswordRule(password.NewPwd)) return
    if (!CheckPassword) {
      if (Token) {
        let payload = {
          Loader: true,
          PartnerId: PartnerId,
          TimeZone: 0,
          LanguageId: LanguageId,
          ProductId: null,
          Method: "ChangeClientPassword",
          Controller: "Client",
          CategoryId: null,
          PageIndex: 0,
          PageSize: 100,
          ProviderIds: [],
          Index: null,
          ActivationKey: null,
          MobileNumber: null,
          Code: null,
          RequestData: JSON.stringify({
            ClientId: ClientId,
            OldPassword: password.oldPwd,
            PartnerId: PartnerId,
            NewPassword: password.NewPwd,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await changePassword(payload);
        let deviceIp = await getDeviceIP();
        saveActivity({
          EventPage: "Cashier",
          EventType: "Profile_ChangePwd",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: "",
          attribute3: "",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
      setpassword({
        oldPwd: "",
        NewPwd: "",
        ConfirmPwd: "",
      });
    }
  };

  return (
    <form action="" autoComplete="off" className="cabinetForm cabinet-block">
			<div className="hide-on-mobile"></div>
			<div>
				<div>
					<div className="cabinet-divider"></div>
					<div className="cabinetForm-rightTitle">{t("Change-password")}</div>
					<div className="cabinet-divider"></div>
				</div>
				<div className="cabinetForm-form">
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Old-password")}</p>
						<TextField 
							className="theme-input" 
							value={password.oldPwd}
							placeholder={t("Old-password")}
							variant="outlined" 
							type="password"
							onChange={(e) =>
								setpassword({ ...password, oldPwd: e.target.value })
							}
						/>
					</div>
					<div className="inputGroup">
						<p className="inputGroup-label">{t("New-password")}</p>
						<TextField 
							className="theme-input" 
							value={password.NewPwd}
							data-e2e={t("New-password")}
							placeholder={t("New-password")}
							variant="outlined" 
							type="password"
							onChange={(e) =>
								setpassword({ ...password, NewPwd: e.target.value })
							}
						/>
					</div>
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Confirm-password")}</p>
						<TextField 
							className="theme-input" 
							value={password.ConfirmPwd}
							data-e2e={t("Confirm-password")}
							placeholder={t("Confirm-password")}
							variant="outlined" 
							type="password"
							onChange={(e) =>
								setpassword({ ...password, ConfirmPwd: e.target.value })
							}
						/>
						{password.NewPwd.length > 0 && !testPasswordRule(password.NewPwd) && <CustomAlert severity="error" text={t("Password_Validation")} />}
						{CheckPassword ? (
							<CustomAlert severity="error" text={t("PasswordMatchTo")} />
						) : null}
						{showResponseError && <CustomAlert text={t("InvalidOldPassword")} severity="error" /> }
					</div>
					<p className="cabinetForm-text">* {t("Password_Validation")}</p>
				</div>
			</div>
			<div className="cabinetForm-btn">
				<div data-e2e={t("Change-password")} className="theme-btn" onClick={HandleChangePassword}>
					{t("Change-password")}
				</div>
			</div>
    </form>
  );
};

export default connect(null, {
  changePassword,
})(RightSettings);
