import axios from "axios";
import { apiVars } from "../../../constant";

class sportsApi {
  getSportsProductURL = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/GetProductUrl`,
      data
    );
}

const api = new sportsApi();
export default api;
