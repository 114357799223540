import { useContext } from "react";
import CasherNav from "../../../modules/Casher/context/context";
import ProfilePasswordForm from "./Settings/ProfilePasswordForm";

const RightSection = ({ editStatus, handleEditStatus, settingSelect }) => {
  const { profileUrl } = useContext(CasherNav);
  const main = () => {
    if (profileUrl === "Settings")
      return <ProfilePasswordForm settingSelect={settingSelect} />;
  };

  return (
		<div>
			{main()}
		</div>
  );
};

export default RightSection;
