import axios from "axios";
import { apiVars } from "../../../../../constant";

class ProfileApi {
    updateProfileInfo = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data);
    sendVerificationCode = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data)
    documentVerification = (data) => axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, data)
}

const api = new ProfileApi();
export default api;