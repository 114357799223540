import SwiperCore, { Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Images } from "../Images";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../Route/URLs";
import BannerLogoDesktop from "./icons/BannerLogoDesktop";
import BannerLogoMobile from "./icons/BannerLogoMobile";

export default function Banner() {
  SwiperCore.use([Pagination, Autoplay, EffectFade]);

  const swiperRef = useRef(null);
  const video1 = useRef(null);

  const handleSlideChange = () => {
    const activeSlide = swiperRef.current.swiper.activeIndex;
    const videos = document.querySelectorAll(".swiper-slide video");
    videos.forEach((video, index) => {
      if (index === activeSlide) {
        video.play();
      } else {
      }
    });
  };

  useEffect(() => {
    setTimeout(function () {
      video1.current.play();
    }, 150);
  }, []);

  return (
    <>
      <div className="banner content-px">
        <Swiper
          onSlideChange={handleSlideChange}
          initialSlide={0}
          speed={500}
          ref={swiperRef}
          dir={"ltr"}
          modules={[Pagination, Autoplay, EffectFade]}
          slidesPerView={1}
          autoplay={{ delay: 3500, pauseOnMouseEnter: true, disableOnInteraction: true }}
          loop={true}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          pagination={{ dynamicBullets: true, clickable: true }}>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.CASINO}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                className="right-position"
                ref={video1}
                no-controls="true"
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage1})` }}>
                <source
                  src={Images.banner1video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.SPORTS}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage2})` }}>
                <source
                  src={Images.banner2video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.CASINO}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage3})` }}>
                <source
                  src={Images.banner3video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.SPORTS}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage4})` }}>
                <source
                  src={Images.banner4video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.CASINO}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage5})` }}>
                <source
                  src={Images.banner5video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.SPORTS}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage6})` }}>
                <source
                  src={Images.banner6video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <NavLink
                className="banner-slide-link"
                to={routes.CASINO}
              />
              <BannerLogoDesktop />
              <BannerLogoMobile />
              <video
                muted
                poster=""
                style={{ backgroundImage: `url(${Images.videoBannerImage7})` }}>
                <source
                  src={Images.banner7video}
                  type="video/webm"
                />
              </video>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
