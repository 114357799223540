import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { languageDropdown } from '../constant';

function LoadData(data, fallback, lng) {
	const availableLanguageKeys = languageDropdown.map(x => x.key);
	i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			fallbackLng: "en",
			debug: false,
			keySeparator: false, 
			interpolation: {
				escapeValue: false, 
			},
			resources: data,
		});
		
		i18n.changeLanguage()
		let langWithoutCode = i18n.language[0] + i18n.language[1];
		if (!availableLanguageKeys.includes(langWithoutCode)) langWithoutCode = "en";
		i18n.changeLanguage(langWithoutCode)
}

const ChangeLanguage = (lang) => {
	i18n.changeLanguage(lang)
}

export const Localisation = {
	LoadData,
	ChangeLanguage,
}
