import React from "react";
import { Navigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../utils/localStorage";

const PrivateRouteLogin = ({ component: Component }) => {
  let token = getItemFromLocalStorage("Token");
  if (token) {
    return <Navigate to="/" />;
  }
  return <Component />;
};

export default PrivateRouteLogin;
