import SwiperCore, { Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import { Images } from "../../Images";

export default function DepositSlider() {
  SwiperCore.use([Pagination, Autoplay, EffectFade]);

  const swiperRef = useRef(null);

  return (
    <>
      <div className="deposit__slider">
        <Swiper
          speed={500}
          ref={swiperRef}
          dir={"ltr"}
          modules={[Pagination, Autoplay, EffectFade]}
          slidesPerView={1}
          // autoplay={{ delay: 3500, pauseOnMouseEnter: true, disableOnInteraction: true }}
          loop={true}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          pagination={{ dynamicBullets: true, clickable: true }}>
          <SwiperSlide>
            <img
              src={Images.depositBanner1}
              className="deposit__slider-slide slide-desktop"
              alt=""
            />
            <img
              src={Images.depositBanner1Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Images.depositBanner2}
              className="deposit__slider-slide slide-desktop"
              alt=""
            />
            <img
              src={Images.depositBanner2Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Images.depositBanner3}
              className="deposit__slider-slide slide-desktop slide-center"
              alt=""
            />
            <img
              src={Images.depositBanner3Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Images.depositBanner4}
              className="deposit__slider-slide slide-desktop slide-right"
              alt=""
            />
            <img
              src={Images.depositBanner4Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Images.depositBanner5}
              className="deposit__slider-slide slide-desktop slide-right"
              alt=""
            />
            <img
              src={Images.depositBanner5Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Images.depositBanner6}
              className="deposit__slider-slide slide-desktop"
              alt=""
            />
            <img
              src={Images.depositBanner6Mobile}
              className="deposit__slider-slide slide-mobile"
              alt=""
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
