import React from "react";

export default function CloseDialogBtn({onClose}) {
	return (
		<button onClick={onClose} type="button" className="theme-modal-close">
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M24 8L8 24M8 8L24 24" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</button>
	)
}