import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const BonusesForms = ({
  setStatusSelected,
  bonusStatusList,
  setBonus,
  promocode,
  setPromocode,
  bonus,
  getBounses,
  handlePromocode,
	statusSelected
}) => {
  const { t } = useTranslation();

  return (
		<div className="cabinet-subNav cabinet-subNav-promo">
			<div className="bonus-promo">
				<div className="bonus-promo-btns">
					<button type="button" className={`${bonus===1 ? "active" : ""} cabinet-text bonus-promo-btn`} onClick={()=>setBonus(1)}>{t("Common")}</button>
					<button type="button" onClick={()=>setBonus(6)} className={`${bonus===6 ? "active" : ""} cabinet-text bonus-promo-btn`}>{t("Sportsbook")}</button>
				</div>
				<div className="bonus-promo-row">
					<div className="bonus-promo-blockBtn">
						<TextField 
							className="bonus-promo-input theme-input" 
							placeholder={t("PromoCode-placeholder")}
							variant="outlined" 
							value={promocode}
							onChange={(e)=>setPromocode(e.target.value)}
						/>
						<button className={`theme-btn ${!promocode && "cursor-disable"}`} disabled={!promocode} onClick={handlePromocode}>
							{t("ActivateBonus")}
						</button>
					</div>
				</div>
			</div>
			<div className="bonus-promo-show">
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Status")}</p>
					<Select
						value={statusSelected}
						displayEmpty
						MenuProps={{
							className: "custom-select-dropdown", 
						}}
						onChange={(e) => setStatusSelected(e.target.value)}
						renderValue={(selected) => {
							if (!selected) {
								return t("Select Status");
							}
							const selectedStatus = bonusStatusList.find((status) => status.Id === selected);
    					return t(selectedStatus?.Name);
						}}
					>
 						{bonusStatusList && bonusStatusList.map((e, i) => {
							return (
								<MenuItem value={e.Id} key={e.Id}>{t(e.Name)}</MenuItem>
							)})
						}
					</Select>
				</div>
				<button onClick={getBounses} className="theme-btn h-40">{t("Show")}</button>
			</div>
		</div>
  );
};

export default BonusesForms;
