export default function ProfileIcon() {
  return (
    <svg
      className="profile-icon"
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.1007 28.1671C40.4692 24.7986 40.4692 19.3372 37.1007 15.9688C33.7323 12.6003 28.2709 12.6003 24.9024 15.9688C21.5339 19.3372 21.5339 24.7986 24.9024 28.1671C28.2709 31.5356 33.7323 31.5356 37.1007 28.1671Z"
        fill="#231F20"
        stroke="#231F20"
        strokeMiterlimit="10"
      />
      <path
        d="M31.0008 31.8501C21.8974 31.8501 14.5176 39.2299 14.5176 48.3333H47.484C47.484 39.2299 40.1043 31.8501 31.0008 31.8501Z"
        fill="#231F20"
        stroke="#231F20"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
