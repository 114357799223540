import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const TransactionFilters = ({ settransectionSelectValue, transectionSelectValue, TypeOption }) => {
  const { t } = useTranslation();
	
	
  const handleDateChange = (e) => {
		const { target: { value } } = e;
		let period;
		switch(value) {
			case '24 hours':  
				period = 1
				break;
			case '1 month':  
				period = 30
				break;
			default:
				period = Number(value.split(" ")[0])
				break
		}
    settransectionSelectValue({
      ...transectionSelectValue,
      time: e.value,
      date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
        "YYYY-MM-DD hh:mm"
      ),
      fromDate: moment(
        new Date(Date.now() - period * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD hh:mm"),
    });
  };

  return (
    <div className="cabinet-subNav-history-selects">
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Period-of-time")}</p>
				<Select
          defaultValue={transectionSelectValue.time}
          onChange={handleDateChange}
					MenuProps={{
						className: "custom-select-dropdown", 
					}}
        >
					<MenuItem value={"24 hours"}>{t("24 hours")}</MenuItem>
					<MenuItem value={"3 days"}>{t("3 days")}</MenuItem>
					<MenuItem value={"7 days"}>{t("7 days")}</MenuItem>
					<MenuItem value={"1 month"}>{t("1 month")}</MenuItem>
        </Select>
      </div>
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Type")}</p>
				<Select
          defaultValue={transectionSelectValue.type}
          onChange={(e) => settransectionSelectValue({...transectionSelectValue, type: e.target.value })}
					MenuProps={{
						className: "custom-select-dropdown", 
					}}
			 	>
					<MenuItem value="all">{t("All")}</MenuItem>
					{TypeOption && TypeOption.map((e, i) => {
						return (
							<MenuItem value={e.Id} key={e.Id}>{t(e.Name)}</MenuItem>
						)})
					}
        </Select>
      </div>
    </div>
  );
};

export default TransactionFilters;
