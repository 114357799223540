import axios from "axios";
import { apiVars } from "../../constant";
import { getDeviceIP } from "../Common/getIp";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { saveActivity } from "../ActivityTracking/activityTrackingService";
import browser from "browser-detect";

export async function fetchProviders(setProviders) {
  const url = `https://websitewebapi.oceanbet.io/${apiVars.partnerId}/api/Main/GetGameProviders`;
  try {
    const { data: res } = await axios.post(url, {
      CategoryId: null,
      LanguageId: "en",
      Method: "GetGameProviders",
      PageIndex: 0,
      PageSize: 100,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
    });
    setProviders(res.ResponseObject?.Providers);
  } catch (err) {
    console.log(err);
  }
}

export async function fetchCategoriesMenu(setCategoriesMenu) {
  // const url = "https://oceanbet.casino/assets/json/menu.json?=554";
  const api_url = localStorage.getItem("api_url");
  const menuUrl = window.location.hostname === "localhost" ? "https://dev.onyxbetuk.com" : api_url;
  const url = `${menuUrl}/assets/json/menu.json?=554`;

  try {
    const { data: res } = await axios.get(url);
    if (res) {
      const type = res.MenuList.find((val) => val.Type === "CasinoMenu");
      const categories = type?.Items;
      setCategoriesMenu(categories);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function activityTrack(searchText) {
  const browserInfo = browser();
  let deviceIp = await getDeviceIP();
  saveActivity({
    EventPage: "Casino",
    EventType: "Search",
    attribute1: getItemFromLocalStorage("UserName") || "",
    attribute2: searchText || "",
    attribute3: navigator.language,
    device_type: browserInfo.mobile ? "Mobile" : "Desktop",
    browser_type: browserInfo.name,
    browser_version: browserInfo.version,
    language: navigator.language,
    ip_address: deviceIp,
  });
}
