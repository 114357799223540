import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../Images";
import { contactData } from "../../constant";
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';


const ContactSocial = () => {
  const { t } = useTranslation();
	const [isCopied, setIsCopied] = useState(false);
	
	function copy(value) {
		navigator.clipboard.writeText(value);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	}
	
  return (
    <div>
      <div className="support-contact">
        <div className="support-faq-header">
          <p>{t("Contact details")}</p>
        </div>
        <div className="support-faq-divider"></div>
        <div className="support-faq-block">
          <p className="support-contact-title">OnyxBet</p>
					<p className="support-contact-text">To contact OnyxBet Support team use the email provided below.</p>
						<div className="support-contact-group">
							<div className="support-contact-group-icon">
								<img width="40" height="40" loading="lazy" src={Images.blackEmailIcon} alt="email icon" />
								<div>
									<div className="support-contact-group-title">{t("Email")}:</div>
									<a href={`mailto:${contactData["email"]}`} className="support-contact-group-text">
										{contactData["email"]}
									</a>
								</div>
							</div>
							<div className="support-contact-group-right">
								<a href={`mailto:${contactData["email"]}`} className="support-contact-group-text">
									{contactData["email"]}
								</a>
								<button className="support-contact-copy" type="button" onClick={() => copy(contactData["email"])}>
									{isCopied ? 
									<CheckTwoToneIcon />
									:
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M17 9.667C17 8.95967 16.719 8.28131 16.2189 7.78115C15.7187 7.28099 15.0403 7 14.333 7H5.667C5.31676 7 4.96996 7.06898 4.64638 7.20301C4.32281 7.33704 4.0288 7.53349 3.78115 7.78115C3.53349 8.0288 3.33704 8.32281 3.20301 8.64638C3.06898 8.96996 3 9.31676 3 9.667V18.333C3 18.6832 3.06898 19.03 3.20301 19.3536C3.33704 19.6772 3.53349 19.9712 3.78115 20.2189C4.0288 20.4665 4.32281 20.663 4.64638 20.797C4.96996 20.931 5.31676 21 5.667 21H14.333C14.6832 21 15.03 20.931 15.3536 20.797C15.6772 20.663 15.9712 20.4665 16.2189 20.2189C16.4665 19.9712 16.663 19.6772 16.797 19.3536C16.931 19.03 17 18.6832 17 18.333V9.667Z" stroke="#EEAF5F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M19.988 16.737C20.2947 16.5622 20.5497 16.3095 20.7274 16.0045C20.9051 15.6995 20.9991 15.353 21 15V5C21 3.9 20.1 3 19 3H9C8.25 3 7.842 3.385 7.5 4" stroke="#EEAF5F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									}
								</button>
							</div>
						</div>
        </div>
      </div>
    </div>
  );
};

export default ContactSocial;
