import BasicLayout from "../BasicLayout";
// import { links, terms } from "../lib/constants";
import { useTranslation } from "react-i18next";

// import "./termsCondition.css";

const TermsPage = () => {
  const { t } = useTranslation();
	
  return (
    <BasicLayout>
      <div className="uw-content content-px content-py">
				<div className="legal">
					<div className="legal__title">{t('Terms and Conditions')}</div>
					<div className="legal__content">
						<h2>{t("sports_book_rules")}</h2>	
						<p className="mb"><span>{t("betting_rules")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("betting_rules_text") }}></p>	
						<p className="mt mb"><span>{t("glossary")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("glossary_text_1") }}></p>	
						<br/>
						<p className="white-color">{t("glossary_example")}</p>
						<br/>
						<p dangerouslySetInnerHTML={{ __html: t("glossary_text_2") }}></p>	
						<div className="legal__table-container">
							<table className="legal__table legal__table_1">
								<thead>
									<tr>
										<th>{t("event_participant")}</th>
										<th>{t("handicap_double")}</th>
										<th>{t("odd")}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("real")}</td>
										<td>-0.25 (0, -0.5)</td>
										<td>2.0</td>
									</tr>
									<tr>
										<td>{t("barcelona")}</td>
										<td>+0.25 (0, +0.5)</td>
										<td>1.8</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p dangerouslySetInnerHTML={{ __html: t("glossary_text_3") }}></p>	
						<br/>
						<p className="white-color" dangerouslySetInnerHTML={{ __html: t("glossary_example") }}></p>	
						<br/>
						<p dangerouslySetInnerHTML={{ __html: t("glossary_text_4") }}></p>	
						<p className="mt mb"><span>{t("policy_bets")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("policy_bets_text") }}></p>	
						<p className="mt mb"><span>{t("displaying_data")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("displaying_data_text") }}></p>	
						<p className="mt mb"><span>{t("interlocking_events")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("interlocking_events_text") }}></p>	
						<p className="mt mb"><span>{t("obv_errors")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("obv_errors_text") }}></p>	
						<p className="mt mb"><span>{t("bets_credit")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("bets_credit_text") }}></p>	
						<p className="mt mb"><span>{t("late_bets")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("late_bets_text") }}></p>	
						<p className="mt mb"><span>{t("return")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("return_text_1") }}></p>	
						<br/>
						<p className="white-color">{t("return_example")}</p>	
						<br/>
						<p>{t("return_express")}</p>	
						<div className="legal__table-container">
							<table className="legal__table">
								<thead>
									<tr>
										<th>{t("position")}</th>
										<th>{t("outcome")}</th>
										<th>{t("coefficient")}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("finland_moldova")}</td>
										<td>{t("win_1")}</td>
										<td>1.55</td>
									</tr>
									<tr>
										<td>{t("malta_croatia")}</td>
										<td>{t("croatia_handicap")}</td>
										<td>1.7</td>
									</tr>
									<tr>
										<td>{t("bulgaria_england")}</td>
										<td>{t("win_2")}</td>
										<td>1.5</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p dangerouslySetInnerHTML={{ __html: t("return_text_2") }}></p>	
						<p className="mt mb"><span>{t("match_fixing")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("match_fixing_text") }}></p>	
						<p className="mt mb"><span>{t("neutral_field")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("neutral_field_text") }}></p>	
						<p className="mt mb"><span>{t("inter_events")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("inter_events_text_1") }}></p>	
						<div className="legal__table-grid">
							<div className="legal__table-container">
								<table className="legal__table">
									<thead>
										<tr>
											<th>{t("inter_sports")}</th>
											<th>{t("played_time")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{t("soccer")}</td>
											<td className="td-min-width">{t("70_mins")}</td>
										</tr>
										<tr>
											<td>{t("ice_hockey")}</td>
											<td className="td-min-width">{t("50_mins")}</td>
										</tr>
										<tr>
											<td>{t("basketball")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("basketball_text") }}></td>
										</tr>
										<tr>
											<td>{t("bandy")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("bandy_text") }}></td>
										</tr>
										<tr>
											<td>{t("floor_hockey")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("floor_hockey_text") }}></td>
										</tr>
										<tr>
											<td>{t("rugby")}</td>
											<td className="td-min-width">{t("65_mins")}</td>
										</tr>
										<tr>
											<td>{t("american_football")}</td>
											<td className="td-min-width">{t("50_mins")}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="legal__table-container">
								<table className="legal__table">
									<thead>
										<tr>
											<th>{t("inter_sports")}</th>
											<th>{t("played_time")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{t("australian_football")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("australian_football_text") }}></td>
										</tr>
										<tr>
											<td>{t("floorball")}</td>
											<td className="td-min-width">{t("50_mins")}</td>
										</tr>
										<tr>
											<td>{t("handball")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("handball_text") }}></td>
										</tr>
										<tr>
											<td>{t("beach_football")}</td>
											<td>{t("30_mins")}</td>
										</tr>
										<tr>
											<td>{t("futsal")}</td>
											<td className="td-min-width" dangerouslySetInnerHTML={{ __html: t("futsal_text") }}></td>
										</tr>
										<tr>
											<td>{t("baseball")}</td>
											<td className="td-min-width">{t("5_innings")}</td>
										</tr>
										<tr>
											<td>{t("curling")}</td>
											<td className="td-min-width">{t("5_ends")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<p dangerouslySetInnerHTML={{ __html: t("inter_events_text_2") }}></p>	
						<p className="mt mb"><span>{t("events_transfer")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("events_transfer_text") }}></p>	
						<p className="mt mb"><span>{t("calculation_bets")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("calculation_bets_text") }}></p>	
						<p className="mt mb"><span>{t("groups_limit")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("groups_limit_text") }}></p>	
						<p className="mt mb"><span>{t("rules_sports")}</span></p>	
						<p dangerouslySetInnerHTML={{ __html: t("rules_sports_text") }}></p>	
					</div>
				</div>
				
      </div>
    </BasicLayout>
  );
};

export default TermsPage;
