import React from "react";

export default function PageLoading() {
  return (
    <>
      <br />
      <div className="page-loading">
        <div className="page-loading-loader"></div>
      </div>
    </>
  );
}
