import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

export default function DepositWalletItem({ data }) {
  const [isCopied, setIsCopied] = useState(false);

  function copy() {
    navigator.clipboard.writeText(data.address);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }

  return (
    <div className="deposit__wallets-item">
      <div>
        <div className="deposit__wallets-left">
          <p className="deposit__wallets-network">{data.network}</p>
          <div className="deposit__wallets-icons">{data.icon}</div>
          <p className="deposit__wallets-currencies">{data.currencies}</p>
        </div>
      </div>
      <button
        className={`deposit__wallets-address ${isCopied ? "no-hover" : ""}`}
        onClick={copy}>
        <p>{data.address}</p>
        {!isCopied ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none">
            <path
              d="M9.5625 11.75H4.3125C3.86495 11.75 3.43573 11.5722 3.11926 11.2557C2.80279 10.9393 2.625 10.5101 2.625 10.0625V4.8125C2.625 4.36495 2.80279 3.93573 3.11926 3.61926C3.43573 3.30279 3.86495 3.125 4.3125 3.125H9.5625C10.0101 3.125 10.4393 3.30279 10.7557 3.61926C11.0722 3.93573 11.25 4.36495 11.25 4.8125V10.0625C11.25 10.5101 11.0722 10.9393 10.7557 11.2557C10.4393 11.5722 10.0101 11.75 9.5625 11.75Z"
              fill="white"
            />
            <path
              d="M3.75 2.375H9.27844C9.16176 2.04623 8.94622 1.76161 8.66138 1.56019C8.37654 1.35877 8.03636 1.25042 7.6875 1.25H2.4375C1.98995 1.25 1.56072 1.42779 1.24426 1.74426C0.92779 2.06072 0.75 2.48995 0.75 2.9375V8.1875C0.750421 8.53636 0.858773 8.87654 1.06019 9.16138C1.26161 9.44622 1.54623 9.66176 1.875 9.77844V4.25C1.875 3.75272 2.07254 3.27581 2.42417 2.92417C2.77581 2.57254 3.25272 2.375 3.75 2.375Z"
              fill="white"
            />
          </svg>
        ) : (
          <CheckIcon color="success" />
        )}
      </button>
    </div>
  );
}
