import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { FreeMode, Mousewheel, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
SwiperCore.use([Mousewheel, Scrollbar, FreeMode]);
let providerBaseUrl = "https://onyxbet.com/assets/images/providers/";

export default function GamesProviders({ providers, loading, setProviderId, setPageSize, activeProviderId }) {
  return (
    <div className="casino-providers">
      <Swiper
        modules={[Mousewheel, FreeMode, Scrollbar]}
        slidesPerView={"auto"}
        freeMode={true}
        spaceBetween={10}
        scrollbar={{ draggable: true }}
        mousewheel={{ releaseOnEdges: true, forceToAxis: true }}>
        {providers?.length > 0 &&
          providers?.map(({ Id, Name }, i) => {
            return (
              <SwiperSlide key={Name}>
                <button
                  className={`casino-providers-btn ${Id === activeProviderId ? "selected" : ""}`}
                  onClick={() => {
                    if (!loading) {
                      setProviderId(Id);
                      setPageSize(24);
                    }
                  }}>
                  <img
                    src={`${providerBaseUrl}${Id}.png`}
                    alt={Name || "providers"}
                  />
                </button>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
