import BasicLayout from "../BasicLayout";
import ContactSocial from "../Components/Support/ContactSocial";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
	
  return (
    <BasicLayout>
			<div className="support">
				<div className="uw-content content-px content-py">
					<p className="page-title keep-in-view">{t("Support")}</p>
					<div className="support-grid">
						<ContactSocial />
					</div>
				</div>
			</div>
    </BasicLayout>
  );
};

export default Support;

