import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import ProfileIcon from "../icons/ProfileIcon";

export default function ProfileBlock() {
  const [userbalance, setuserbalance] = useState(0);

  const username = getItemFromLocalStorage("UserName");
  const userData = getItemFromLocalStorage("userData");

  const AvailableBalance = useSelector((state) => state?.headerReducer?.getClientBalanceData?.data?.AvailableBalance);
  const isClientBalance = useSelector((state) => state?.headerReducer?.isClientBalance);

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance);
    }
  }, [AvailableBalance, isClientBalance]);

  return (
    <>
      <div className="sidebar-profile block-fill">
        <div className="sidebar-profile-img">
          <ProfileIcon />
          {/* <img
            width="60"
            height="60"
            src={Images.userIcon}
            alt="user icon"
            loading="lazy"
          /> */}
        </div>
        <p className="sidebar-profile-username">{username}</p>
        <div className="sidebar-profile-balance">
          {userData?.CurrencySymbol} {userbalance}
        </div>
      </div>
    </>
  );
}
