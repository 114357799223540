import axios from "axios";
import { apiVars } from "../../../constant";

class LiveCasinoApi {
  getProductURL = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/GetProductUrl`,
      data
    );
}
const api = new LiveCasinoApi();
export default api;
