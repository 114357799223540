import { useTranslation } from "react-i18next";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PageLoading from "../PageLoading";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { replaceGamesKeysNames } from "../../utils/helper";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { casino } from "../../modules/Casino/actions";
import GameCards from "./GameCards";
import GamesProviders from "./GamesProviders";
import GamesCategories from "./GamesCategories";
import { fetchProviders, fetchCategoriesMenu, activityTrack } from "./api";
import GameNavbarSearch from "./GameNavbarSearch";
import { GameProvidersSwitch } from "./GameProviderSwitch";

const GameNavbar = (props) => {
  const { t } = useTranslation();
  const { casino, gamesData, loading, searchText, setSearchText, scrollloading } = props;

  const listInnerRef = useRef();
  const observer = useRef();

  const [pageSize, setPageSize] = useState(24);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);

  const [categoriesMenu, setCategoriesMenu] = useState([]);
  const [providers, setProviders] = useState([]);
  const [gameCategoryId, setGameCategoryId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [games, setGames] = useState([]);
  const [showGameProviders, setShowGameProviders] = useState(true);
  const [favouriteStatus, setFavouriteStatus] = useState(false);

  const Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const handleFavoruiteStatus = () => setFavouriteStatus((prev) => !prev);

  useEffect(() => {
    fetchCategoriesMenu(setCategoriesMenu);
    fetchProviders(setProviders);

    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setGames(gamesData?.Games);
  }, [gamesData]);

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageSize((prev) => prev + 24);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: pageSize,
      WithWidget: false,
      CategoryId: gameCategoryId === "all-games" ? null : parseInt(gameCategoryId),
      IsForMobile: false,
      Name: searchText,
      LanguageId: LanguageId,
      ProviderIds: providerId ? [providerId] : null,
    };
    if (Token) {
      payload = {
        ...payload,
        ClientId: ClientId || "",
        Token: Token || "",
      };
    }
    await casino(payload);
  };

  function setShowGameProvidersHandler() {
    if (windowSize >= 991) {
      setShowGameProviders((prev) => !prev);
    }
  }
  if (windowSize < 991 && !showGameProviders) {
    setShowGameProviders(true);
  }

  useEffect(() => {
    getGamesApi();
    activityTrack();
    // eslint-disable-next-line
  }, [searchText, gameCategoryId, favouriteStatus, pageSize, providerId]);

  const resetProviderId = () => setProviderId(null);

  return (
    <div className="casino-content">
      <div className="uw-content content-px">
        <div className="casino-nav">
          {categoriesMenu && categoriesMenu.length > 0 && (
            <GamesCategories
              categoriesMenu={categoriesMenu}
              loading={loading}
              fetchProviders={fetchProviders}
              setProviders={setProviders}
              setPageSize={setPageSize}
              setGameCategoryId={setGameCategoryId}
              gameCategoryId={gameCategoryId}
            />
          )}
          <GameNavbarSearch
            setSearchText={setSearchText}
            searchText={searchText}
          />
        </div>
        <div className="casino-providers-top">
          <p className="casino-providers-title">{t("Game_Providers")}</p>
          {windowSize >= 991 && (
            <div className="casino-providers-switch">
              <p className="casino-providers-show">{t("Show_Providers")}</p>
              <GameProvidersSwitch
                checked={showGameProviders}
                onChange={setShowGameProvidersHandler}
              />
            </div>
          )}
        </div>
        {providerId && showGameProviders && (
          <button
            type="button"
            onClick={resetProviderId}
            className="casino-providers-reset">
            <RestartAltIcon />
            {t("reset_provider")}
          </button>
        )}

        {showGameProviders && (
          <GamesProviders
            activeProviderId={providerId}
            loading={loading}
            providers={providers}
            setProviderId={setProviderId}
            setPageSize={setPageSize}
          />
        )}

        <div className="casino-games">
          {!loading && games && games?.length > 0 && (
            <div
              className="casino-games-grid"
              ref={listInnerRef}
              id="infinite-list"
              style={{ overflow: "auto" }}>
              {games?.map((game, i) => {
                let result = replaceGamesKeysNames(game);
                return (
                  <div
                    key={i}
                    ref={lastBookElementRef}>
                    <GameCards
                      data={result}
                      handleFavoruiteStatus={handleFavoruiteStatus}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {loading && <PageLoading />}
          {scrollloading && !loading && (
            <div>
              <br />
              <br />
              <br />
              <PageLoading />
            </div>
          )}
          {!loading && !scrollloading && games && games?.length < 1 && <p className="no-results t-center">{t("No games found")}</p>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.casinoReducer?.casinoGames,
    loading: state?.casinoReducer?.loading,
    scrollloading: state?.casinoReducer?.scrollloading,
  };
};

export default connect(mapStateToProps, {
  casino,
})(GameNavbar);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
