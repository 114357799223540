import { isNotEmptyObject, isStringified } from '../utils/helper'

export const checkLocalStorageSupport = () => {
  if (typeof(Storage) !== "undefined") {
    return true;
    } else {
    // No web storage Support.
    return false
 }
}

export const addItemToLocalStorage = (key, value) => {
  if (checkLocalStorageSupport()) {
    let valueToStore;
    // Local storage store value as string
    valueToStore = isNotEmptyObject(value) ? JSON.stringify(value) : value;
    window.localStorage.setItem(key, valueToStore)
  }
}

export const removeItemFromLocalStorage = (key) => {
  if (checkLocalStorageSupport()) {
    window.localStorage.removeItem(key)
  }
}

export const getItemFromLocalStorage = (key) => {
  if (checkLocalStorageSupport()){
    const item = window.localStorage.getItem(key);
    return isStringified(item);
  }
}

export const getKeyNameFromLocalStorage = (index) =>{
  if(checkLocalStorageSupport()) {
    return ( index && index > -1 ) ? window.localStorage.key(index) : null;
  }
}

export const clearAllFromLocalStorage = () => {
	console.log("checkLocalStorageSupport")
  if(checkLocalStorageSupport()) {
    window.localStorage.clear();
  }
}