import { Pagination, Table } from "antd";
import { Images } from "../../Images";
import { useTranslation } from "react-i18next";

const MainHistory = ({
  Payhistory,
  navEl,
  empty,
  History,
  SkipCount,
  setSkipCount,
  img,
}) => {
  const { shape, noAccounts, noTicket } = Images;
  const { t } = useTranslation();
  let locale = {
    emptyText: (
			<div className="table-empty">
				<img src={img === "account" ? noAccounts : img === "ticket" ? noTicket : shape} alt="" />
				<div className="table-empty-text">{t(empty)}</div>
			</div>
    ),
  };
  return (
    <>
      <Table
        locale={locale}
        columns={navEl}
        dataSource={Payhistory}
        pagination={false}
        className="tester-assigned-please-dont-remove"
      />
      {History?.Count > 10 && 
        <Pagination
          current={SkipCount + 1}
          total={History?.Count}
          defaultPageSize={10}
          showSizeChanger={false}
          onChange={(e) => setSkipCount(e - 1)}
        />
      }
    </>
  );
};

export default MainHistory;
