import TextField from '@mui/material/TextField';
import browser from "browser-detect";
import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import CloseDialogBtn from "../../global/CloseDialogBtn";
import { connect } from "react-redux";
import {
  generateNewTicket,
  getTicketHistory,
} from "../../../modules/Casher/Services/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../Common/getIp";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../global/CustomAlert";
import { apiVars } from '../../../constant';

const browserInfo = browser();

const NewTicket = (props) => {
  const { t } = useTranslation();
	const [showErrors, setShowErrors] = useState(false);
  const { generateNewTicket, getTicketHistory } = props;

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
	
	const [formData, setFormData] = useState({subject: "", ticket: ""})
  const onInputChange = (e) => {
		setShowErrors(false);
		setFormData({ ...formData, [e.target.name]: e.target.value })
	};
	
  const submit = async (data) => {
		setShowErrors(!formData.ticket || !formData.subject);
		if (!formData.ticket || !formData.subject) return;
    let payload = {
      Loader: true,
      Controller: "Client",
      PartnerId: apiVars.partnerId,
      Method: "OpenTicket",
      TimeZone: apiVars.timeZone,
      RequestData: JSON.stringify({
        Subject: formData.subject,
        Message: formData.ticket,
      }),
      ClientId: ClientId,
      Token: Token,
    };
    await generateNewTicket(payload);
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Ticket_NewTicket",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
    props.setmailmodel(false);
    setTimeout(() => {
      TicketHistory();
    }, 2000);
  };

  const TicketHistory = async () => {
    if (Token) {
      let payload = {
        Loader: true,
        Controller: "Client",
        PartnerId: PartnerId,
        Method: "GetClientTickets",
        TimeZone: 0,
        RequestData: JSON.stringify({
          SkipCount: 0,
          TakeCount: 10,
        }),
        ClientId: ClientId,
        Token: Token,
      };
      await getTicketHistory(payload);
    }
  };

  useEffect(() => {
    TicketHistory();
    //eslint-disable-next-line
  }, []);

  function handleClose(e) {
    e.stopPropagation();
    props.setmailmodel(false);
  }

  return (
    <Dialog
      open={props.mailmodel}
      className="theme-modal mailModal"
      onClose={(e) => handleClose(e)}
    >
			<div className="theme-modal-heading">
				<p className="theme-modal-title">{t("New ticket")}</p>
				<CloseDialogBtn onClose={(e) => handleClose(e)} />
			</div>
			 <div className="theme-modal-body">
				<div className="inputGroup mb">
					<p className="inputGroup-label">{t("Subject")}</p>
					<TextField
						className="theme-input"
						placeholder={t("Please enter subject here")}
						value={formData.subject}
						variant="outlined"
						name="subject"
						onChange={(e) => onInputChange(e)}
					/>
				</div>
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Ticket")}</p>
					<TextField
						className="theme-input"
						placeholder={t("Please enter your message here")}
						value={formData.ticket}
						variant="outlined"
						name="ticket"
						onChange={(e) => onInputChange(e)}
						rows={5}
						multiline
					/>
					{showErrors && <CustomAlert severity="error" text={t("PromptText")} />}
				</div>
				<button className="theme-btn full-width" onClick={submit}>{t("Send")}</button>
			</div>	
    </Dialog>
  );
};

export default connect(null, {
  generateNewTicket,
  getTicketHistory,
})(NewTicket);
