import React from "react";
import PageLoading from "../PageLoading";
const Loading = () => {
  return (
    <div className="preloader">
			<PageLoading />
    </div>
  );
};

export default Loading;
