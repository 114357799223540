export const routes = {
  INDEX_PAGE: "/",
  CASINO: "/casino",
  LIVE_CASINO: "/live-casino",
  LIVE_CASINO_PREVIEW: "/live-casino/preview",
  GAMES: "/games",
  SPORTS: "/sports",
  INPLAY: "/in-play",
  LOGIN: "/login",
  SUPPORT: "/support",
  ABOUT_US: "/about",
  RESPONSIBLE_GAMING: "/responsible-gaming",
  TERMS: "/terms-and-conditions",
  SELF_EXCLUSION: "/self-exclusion",
  DISPUTE_RESOLUTION: "/dispute-resolution",
  AML: "/aml",
  FAIRNESS_RNG_TESTING: "/fairness-rng-testing",
  PRIVACY_POLICY: "/privacy-policy",
  ACCOUNTS_PAYOUTS_BONUS: "/accounts-payouts-bonus",
  ACCESS_DENIED: "/access-denied",
  HISTORY: "/history",
  BONUSES: "/bonuses",
  PROFILE: "/profile",
  TICKETS: "/tickets",
  BALANCE: "/balance",
  CHANGE_PASSWORD: "/change-password",
  DEPOSIT_PAGE: "/deposit",
};

export const menuKeys = {
  INDEX_PAGE: "home_page",
  CASINO: "casino",
  LIVE_CASINO: "live_casino",
  GAMES: "games",
  SPORTS: "sports",
  INPLAY: "inplay",
  PROMOTIONS: "promotions",
};

// const routes = {
//   ACCESS_DENIED: getRoute(`access-denied`),
//   HOME_PAGE: getRoute(`home`),
//   CASINO: getRoute(`casino`),
//   LIVE_CASINO: getRoute(`live-casino`),
//   LIVE_CASINO_PREVIEW: getRoute(`live-casino/preview`),
//   GAMES: getRoute(`games`),
//   SPORTS: getRoute(`sports`),
//   INPLAY: getRoute(`inplay`),
//   PROMOTIONS: getRoute(`promotions`),
//   CASHER: getRoute(`casher`),
//   CASHER_WALLETS: getRoute(`casher/wallets`),
//   CASHER_HISTORY: getRoute(`casher/history`),
//   CASHER_BONUSES: getRoute(`casher/bonuses`),
//   CASHER_PROFILE: getRoute(`casher/profile`),
//   CASHER_TICKET: getRoute(`casher/ticket`),
//   CASHER_BALANCE: getRoute(`casher/balance`),
//   TERMS_CONDITION: getRoute(`terms&conditions`),
//   SUPPORT: getRoute(`support`),
//   SINGLE_PROMOTION: getRoute(`promotions/:id`),
//   HELP: getRoute(`help`),
//   ABOUT_US: getRoute(`about-us`),
//   RESPONSIBLE_GAMING: getRoute(`responsible-gaming`),
//   SELF_EXCLUSION: getRoute(`self-exclusion`),
//   DISPUTE_RESOLUTION: getRoute(`dispute-resolution`),
//   AMP_PAGE: getRoute(`aml-policy`),
//   FAIRNESS_AND_RNG_TESTING: getRoute(`fairness-rng-testing`),
//   PRIVACY_POLICY: getRoute(`privacy-policy`),
//   ACCOUNTS_PAYOUTS_BONUS: getRoute(`accounts-payouts-bonus`),
// };
// export default routes;
