import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../Images/index";
import { NavLink } from "react-router-dom";
import { routes } from "../../Route/URLs";

export default function Footer() {
  const { t } = useTranslation();
  const footerList = [
    {
      list: [
        { title: t("About us"), path: routes.ABOUT_US },
        { title: t("Support"), path: "/support" },
      ],
      title: "OnyxBet",
    },
    {
      list: [
        { title: t("Live Casino"), path: "/live-casino" },
        { title: t("Casino"), path: "/casino" },
        { title: t("Sports"), path: "/sports" },
        { title: t("In-Play"), path: "/in-play" },
        { title: t("Games"), path: "/games" },
      ],
      title: t("Products"),
    },
    {
      list: [
        { title: "Pragmatic Play", path: "/live-casino/pragmatic-play" },
        { title: "Evolution", path: "/live-casino/evolution" },
        { title: "Ezugi", path: "/live-casino/ezugi" },
        { title: "Vivo Gaming", path: "/live-casino/vivo-gaming" },
      ],
      title: t("Live Casino"),
    },
    {
      list: [{ title: t("Terms and Conditions"), path: "/terms-and-conditions" }],
      title: t("Information"),
    },
  ];

  function toggleTab(e) {
    const parent = e.currentTarget.parentElement;
    if (parent.classList.contains("active")) {
      parent.classList.remove("active");
      return;
    } else {
      parent.classList.add("active");
    }
  }

  let location = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="footer">
      <div className="content-px uw-content">
        <div className="footer-menu-block">
          <div className="footer-menu">
            {footerList.map((list, i) => {
              return (
                <div
                  key={list.title}
                  className="footer-menu-block">
                  <button
                    onClick={(e) => toggleTab(e)}
                    className="footer-menu-header">
                    <p className="footer-menu-title">{list.title}</p>
                    <img
                      width="10"
                      height="7"
                      loading="lazy"
                      src={Images.downArrowGray}
                      alt="icon"
                    />
                  </button>
                  <div className="footer-menu-tab">
                    {list.list.map(({ title, path }) => {
                      return (
                        <div
                          key={title}
                          className="footer-menu-link-block">
                          <NavLink
                            to={path}
                            className="footer-menu-link">
                            {title}
                          </NavLink>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="footer-slogan"
          style={{ backgroundImage: `url(${Images.safeBetterBg})` }}>
          <div className="footer-slogan-bg"></div>
          <div className="footer-slogan-content">
            <img
              className="footer-slogan-logo"
              width="165"
              height="60"
              src={Images.footerLogo}
              alt="logo"
            />
            <div className="footer-slogan-line"></div>
            <div className="">
              <div className="footer-slogan-title">Members only high roller club</div>
            </div>
          </div>
        </div>
        <div className="footer-text">{t("copyright_title")}</div>
      </div>
    </footer>
  );
}
