import { combineReducers } from "redux";
import { configReducer } from "./configReducer";
import LoginReducer from "../../modules/Login/reducers"
import GamesReducer from "../../modules/Games/reducers";
import LiveCasinoReducer from "../../modules/LiveCasino/reducers";
import HeaderReducer from "../../modules/Navbar/reducers";
import ThemeReducer from "../../theme/reducers";
import CashierReducer from "../../modules/Casher/Services/reducers";
import BonusReducer from "../../modules/Casher/Bonuses/Services/reducers";
import ProfileReducer from "../../modules/Casher/Profile/Services/reducers";
import SportsReducer from "../../modules/Sports/reducers/index";
import InplayReducer from "../../modules/Inplay/Services/reducers";
import HomeReducer from "../../modules/Home/Services/reducers";
import CasinoReducer from "../../modules/Casino/reducers";

export default combineReducers({
  config: configReducer,
  loginReducer: LoginReducer,
  gamesReducer: GamesReducer,
  liveCasinoReducer: LiveCasinoReducer,
  headerReducer: HeaderReducer,
  themeReducer: ThemeReducer,
  cashierReducer: CashierReducer,
  bounsReducer: BonusReducer,
  profileReducer: ProfileReducer,
  sportsReducer: SportsReducer,
  inplayReducer: InplayReducer,
  homeReducer: HomeReducer,
  casinoReducer: CasinoReducer,
});
