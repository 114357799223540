import React, { useState, useRef } from "react";
import i18n, { t } from "i18next";
import useOutsideClick from "../../helpers/outsideClick";
import { Link } from "react-router-dom";
import { logout } from "../../modules/Login/actions";
import { useDispatch } from "react-redux";
import Icon from "../global/Icon";
// import { Images } from "../../Images";
import { languageDropdown } from "../../constant";
import ProfileIcon from "../icons/ProfileIcon";

export default function NavbarProfileMenu() {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const activeLang = languageDropdown.find((x) => x.key === i18n.language);
  const textDirectionLeft = activeLang.key === "he" || activeLang.key === "ar";

  function toggleOpen() {
    setOpen((prev) => !prev);
  }

  const refProfileMenu = useRef();
  useOutsideClick(refProfileMenu, () => {
    setOpen(false);
  });

  function handleLogout() {
    dispatch(logout(false));
  }
  return (
    <div
      className="navbar-profile"
      ref={refProfileMenu}>
      <button
        type="button"
        className="navbar-profile-btn"
        onClick={toggleOpen}>
        <ProfileIcon />
      </button>
      <div className={`navbar-profile-menu ${isOpen ? "active" : ""} ${textDirectionLeft ? "navbar-profile-menu-left" : "navbar-profile-menu-right"}`}>
        <Link
          to="/deposit"
          className="navbar-profile-link">
          <Icon id="deposit" />
          {t("Deposit")}
        </Link>
        <Link
          to="/history"
          className="navbar-profile-link">
          <Icon id="history" />
          {t("History")}
        </Link>
        <Link
          to="/bonuses"
          className="navbar-profile-link">
          <Icon id="bonuses" />
          {t("Bonuses")}
        </Link>
        <Link
          to="/profile"
          className="navbar-profile-link">
          <Icon id="profile" />
          {t("Profile")}
        </Link>
        <Link
          to="/tickets"
          className="navbar-profile-link">
          <Icon id="myTickets" />
          {t("My Tickets")}
        </Link>
        <Link
          to="/balance"
          className="navbar-profile-link">
          <Icon id="balance" />
          {t("Balance")}
        </Link>
        <button
          type="button"
          onClick={handleLogout}
          className="navbar-profile-link">
          <Icon id="logout" />
          {t("Logout")}
        </button>
      </div>
    </div>
  );
}
