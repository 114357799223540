// import { StarFilled } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { playNowGameSuccess } from "../../modules/Games/actions";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import getSymbolFromCurrency from "currency-symbol-map";
import { Dialog } from "@mui/material";
import CloseDialogBtn from "../global/CloseDialogBtn";
import PageLoading from "../PageLoading";
import CustomAlert from "../global/CustomAlert";

const CasinoCardGameModal = (props) => {
  const [resize, setResize] = useState(true);
  const { result, gameName, showgameUrl } = props;
  const dispatch = useDispatch();
  const [userbalance, setuserbalance] = useState(0);
  const [gameResponse, setGameResponse] = useState("");

  useEffect(() => {
    setGameResponse(showgameUrl);
  }, [showgameUrl]);

  const AvailableBalance = useSelector((state) => state?.headerReducer?.getClientBalanceData?.data?.AvailableBalance);
  const isClientBalance = useSelector((state) => state?.headerReducer?.isClientBalance);
  const CurrencyId = getItemFromLocalStorage("CurrencyId");

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance);
    }
  }, [AvailableBalance, isClientBalance]);

  // const Rating = () => {
  //   return (
  //     <div className="playNowModal-rating">
  //       <div className="d-flex justify-content-center align-items-center">
  //         <StarFilled style={{ color: "#EEAF5D", padding: "2px" }} />
  //         <p className="playNowModal-rating-value">{result?.rating || "5.0"}</p>
  //       </div>
  //     </div>
  //   );
  // };

  function setResizeHandler() {
    setResize((prev) => !prev);
  }

  function onCloseHandler() {
    dispatch(playNowGameSuccess([]));
    props.setgamemodel(false);
    setTimeout(() => {
      setResizeHandler(true);
    }, 300);
  }

  return (
    <Dialog
      open={props.gamemodel}
      fullScreen={resize}
      className={`theme-modal playNowModal ${resize ? "full-screen" : ""}`}
      onClose={onCloseHandler}>
      <div className="playNowModal-grid">
        <div className="playNowModal-header">
          <div className="playNowModal-header-left">
            <p className="theme-modal-title"> {result?.name ? result?.name : gameName || "DEMO NAME"}</p>
            {/* <Rating /> */}
            <div className="playNowModal-balance">
              Balance: {getSymbolFromCurrency[CurrencyId] || "€"} {userbalance || 0}
            </div>
          </div>
          <div className="playNowModal-actions">
            <button
              onClick={setResizeHandler}
              type="button"
              className="theme-modal-close playNowModal-resize">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.666 9.40093L26.666 22.5992C26.6621 24.844 24.8433 26.6628 22.5985 26.6667L9.40024 26.6667C7.15541 26.6628 5.3366 24.844 5.33268 22.5992L5.33268 9.40093C5.3366 7.1561 7.15541 5.33729 9.40024 5.33337L22.5985 5.33337C24.8433 5.33729 26.6621 7.1561 26.666 9.40093ZM6.75623 22.5978C6.76014 24.0571 7.94221 25.2392 9.40156 25.2431L22.5998 25.2431C24.0591 25.2392 25.2412 24.0571 25.2451 22.5978L25.2451 9.39954C25.2412 7.94019 24.0591 6.75812 22.5998 6.75421L9.40156 6.75421C7.94221 6.75812 6.76014 7.94019 6.75623 9.39954L6.75623 22.5978Z"
                  fill="white"
                />
                <path
                  d="M14.6898 22.4008C14.6898 22.008 14.3714 21.6896 13.9787 21.6896H11.312L21.6872 11.3145V13.9812C21.6872 14.3739 22.0055 14.6923 22.3983 14.6923C22.791 14.6923 23.1094 14.3739 23.1094 13.9812V9.38032C23.1094 9.10933 22.8897 8.88965 22.6187 8.88965L18.0178 8.88965C17.6251 8.88965 17.3067 9.20802 17.3067 9.60076C17.3067 9.9935 17.6251 10.3119 18.0178 10.3119H20.6845L10.3094 20.687V18.0203C10.3094 17.6276 9.991 17.3092 9.59826 17.3092C9.20553 17.3092 8.88715 17.6276 8.88715 18.0203V22.6212C8.88715 22.8922 9.10683 23.1119 9.37782 23.1119H13.9787C14.3714 23.1119 14.6898 22.7935 14.6898 22.4008Z"
                  fill="white"
                />
              </svg>
            </button>
            <CloseDialogBtn onClose={onCloseHandler} />
          </div>
        </div>
        <div className="playNowModal-body">
          <div className={`playNowModal-iframe ${gameResponse?.ResponseCode !== 0 ? "overflow-auto" : ""}`}>
            {gameResponse ? (
              gameResponse?.ResponseCode === 0 ? (
                <iframe
                  src={gameResponse?.ResponseObject}
                  width="100%"
                  title="Games"></iframe>
              ) : (
                <CustomAlert
                  severity="error"
                  text={gameResponse?.Description || "Something went wrong..."}
                />
              )
            ) : (
              <div>
                <br />
                <PageLoading />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CasinoCardGameModal;
