import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Images } from "../../Images";
import { useTranslation } from "react-i18next";
import { cabinetNav } from "../../constant";
import Icon from "../global/Icon";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
	const [activeLink, setActiveLink] = useState(null);
	const [filteredLinks, setFilteredLinks] = useState([]);
	const location = useLocation();
	const { pathname} = location;
	
	useEffect(() => {
		setActiveLink(cabinetNav.find(x => x.route === pathname))
		setFilteredLinks(cabinetNav.filter(x => x.route !== pathname))
	}, [pathname])
	
  return (
    <div className="">
      <div className="cabinet-nav">
        {cabinetNav.map((el, i) => (
          <NavLink className="cabinet-nav-link" to={el.route} key={el.title}>
            <div className="cabinet-nav-link-icon">
              <Icon id={el.icon} />
            </div>
            {t(el.title)}
          </NavLink>
        ))}
      </div>

      <div className="cabinet-nav-m" onClick={() => setShow((prev) => !prev)}>
        {activeLink && <div className={`${ show ? "active" : ""} cabinet-nav-m-selected cabinet-nav-link`}>
          <div className="cabinet-nav-link-icon">
            <Icon id={activeLink.icon} />
          </div>
          {t(activeLink.key)}
          <img  className="cabinet-nav-link-chevron" width="13" height="13" src={Images.chevronIcon} alt="chevron icon" />
        </div>}
        <div className={`${show ? "active" : ""} cabinet-nav-list`}>
          {filteredLinks?.map((el) => {
            return (
							<NavLink
								className="cabinet-nav-link"
								to={el.route}
								key={`${el.key}-m`}
							>
								<div className="cabinet-nav-link-icon">
									<Icon id={el.icon} />
								</div>
								{t(el.key)}
							</NavLink>
						);
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
