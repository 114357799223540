import React from "react";
import NavbarTime from "../Navbar/NavbarTime";
import { Images } from "../../Images";
import ProfileBlock from "./ProfileBlock";
import SidebarMenuLinks from "./SidebarMenuLinks";
import { Link } from "react-router-dom";

export default function SidebarLeft() {
	return (
		<div className="sidebar on-desktop sidebar-between">
			<div>
				<Link to="/" className="sidebar-logo">
					<img src={Images.sideBarLogo} alt="logo" />
				</Link>
				<div className="sidebar-container">
					<div className="sidebar-block">
						<ProfileBlock />
						<SidebarMenuLinks />
					</div>
				</div>
			</div>
			<div className="sidebar-container-time">
				<NavbarTime />
			</div>
		</div>
	)
}