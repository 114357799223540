import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Localisation } from "../locale/Localisation";
// import routes from "./URLs";
import history from "../utils/history";
import Loading from "../Components/Common/Loading";
// import Casher from "../modules/Casher";
// import Promotions from "../modules/Promotions";
// import SinglePromotion from "../modules/Promotions/SinglePromotion";
// import TermsCondition from "../pages/TermsPage";
// import Help from "../modules/Footer/Help/Help";
import { resources } from "../locale/Resources";
import HistoryPage from "../pages/HistoryPage";
import BonusesPage from "../pages/BonusesPage";
import ProfilePage from "../pages/ProfilePage";
// import Ticket from "../modules/Casher/Ticket/Ticket";
// import Balance from "../modules/Casher/Balance/Balance";
// import Wallets from "../modules/Casher/Finances/Wallets";
// import LoadingRedirect from "./LoadingRedirect";

// import Home from "../modules/Home";
//kris: new
import LoginPage from "../pages/LoginPage";
import IndexPage from "../pages/IndexPage";
import { routes } from "./URLs";
import PrivateRouteLogin from "./PrivateRouteLogin";
import SupportPage from "../pages/SupportPage";
import AboutUsPage from "../pages/AboutPage";
import GamesPage from "../pages/GamesPage";
import TermsPage from "../pages/TermsPage";
import CasinoPage from "../pages/CasinoPage";
import TicketPage from "../pages/TicketPage";
import BalancePage from "../pages/BalancePage";
import ChangePassword from "../pages/ChangePassword";
import DepositPage from "../pages/DepositPage";

const LiveCasinoPage = lazy(() => import("../pages/LiveCasinoPage"));
const LiveCasinoPreviewPage = lazy(() => import("../pages/LiveCasinoPreviewPage"));

const ErrorBoundary = lazy(() => import("./ErrorBoundry"));
const PageNotFound = lazy(() => import("./PageNotFound"));
const AccessDenied = lazy(() => import("./AccessDenied"));
const PrivateRouteWithStore = lazy(() => import("./PrivateRouteWithStore"));
// const LiveCasino = lazy(() => import("../modules/LiveCasino"));
// const LiveCasinoPreview = lazy(() =>
//   import("../modules/LiveCasino/LiveCasinoPreview")
// );
const InPlayPage = lazy(() => import("../pages/InPlayPage"));
const SportsPage = lazy(() => import("../pages/SportsPage"));

const IndexRoutes = () => {
  useEffect(() => {
    Localisation.LoadData(resources);
  }, []);

  return (
    <Router history={history}>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Routes>
            <Route
              path="/login"
              element={<PrivateRouteLogin component={LoginPage} />}></Route>
            <Route
              path={routes.INDEX_PAGE}
              element={<PrivateRouteWithStore component={IndexPage} />}
            />
            <Route
              path={routes.LIVE_CASINO}
              element={<PrivateRouteWithStore component={LiveCasinoPage} />}
            />
            <Route
              path="/live-casino/:id"
              element={<PrivateRouteWithStore component={LiveCasinoPreviewPage} />}
            />
            <Route
              path={routes.SUPPORT}
              element={<PrivateRouteWithStore component={SupportPage} />}
            />
            <Route
              path={routes.ABOUT_US}
              element={<PrivateRouteWithStore component={AboutUsPage} />}
            />
            <Route
              path={routes.INPLAY}
              element={<PrivateRouteWithStore component={InPlayPage} />}
            />
            <Route
              path={routes.SPORTS}
              element={<PrivateRouteWithStore component={SportsPage} />}
            />
            <Route
              path={routes.GAMES}
              element={<PrivateRouteWithStore component={GamesPage} />}
            />
            <Route
              path={routes.TERMS}
              element={<PrivateRouteWithStore component={TermsPage} />}
            />
            <Route
              path={routes.CASINO}
              element={<PrivateRouteWithStore component={CasinoPage} />}
            />
            <Route
              path={routes.HISTORY}
              element={<PrivateRouteWithStore component={HistoryPage} />}
            />
            <Route
              exact
              path={routes.BONUSES}
              element={<PrivateRouteWithStore component={BonusesPage} />}
            />
            <Route
              exact
              path={routes.PROFILE}
              element={<PrivateRouteWithStore component={ProfilePage} />}
            />
            <Route
              exact
              path={routes.TICKETS}
              element={<PrivateRouteWithStore component={TicketPage} />}
            />
            <Route
              exact
              path={routes.TERMS}
              element={<PrivateRouteWithStore component={TermsPage} />}
            />
            <Route
              exact
              path={routes.BALANCE}
              element={<PrivateRouteWithStore component={BalancePage} />}
            />
            <Route
              path={routes.CHANGE_PASSWORD}
              element={<PrivateRouteLogin component={ChangePassword} />}
            />
            <Route
              path={routes.DEPOSIT_PAGE}
              element={<PrivateRouteWithStore component={DepositPage} />}
            />
            {/*
						
						
						 */}

            {/* 
						<PrivateRouteWithStore
							exact
							path={routes.PROMOTIONS}
							component={(props) => <Promotions {...props} />}
						/>

						<PrivateRouteWithStore
							exact
							path={routes.SINGLE_PROMOTION}
							component={(props) => <SinglePromotion {...props} />}
						/>  */}
            <Route
              path={routes.ACCESS_DENIED}
              element={<PrivateRouteWithStore component={AccessDenied} />}
            />
            <Route
              path="*"
              element={<PrivateRouteWithStore component={PageNotFound} />}
            />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
};

export default IndexRoutes;
