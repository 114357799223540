import axios from "axios";
import { apiVars } from "../../../constant";

class GamesApi {
  getGames = (data) =>
    axios.post(`${apiVars.baseApiUrl}/Main/GetGames`, data);
  markGameFavourite = (data) =>
    axios.post(
    	`${apiVars.baseApiUrl}/Main/ApiRequest`,
      data
    );
  playGame = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/GetProductUrl`,
      data
    );
}

const api = new GamesApi();
export default api;