import "./gameCard.scss";
import { Images } from "../../Images";
import { saveActivity } from "../ActivityTracking/activityTrackingService";
import { loginModal } from "../../modules/Login/actions";
import { checkLoginStatus, playNowGame } from "../../modules/Games/actions";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { useSelector, connect } from "react-redux";
import CasinoCardGameModal from "../Casino/CasinoCardGameModal";
import { apiVars } from "../../constant";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const GameCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    gameImage,
    name,
    productId,
    navbarcard,
    loginModal,
    playNowGame,
    isRoundImage,
    closeHandler
  } = props;
  const { t } = useTranslation();
  let ClientId = getItemFromLocalStorage("Id");
  let Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [gamemodel, setgamemodel] = useState(false);
  const showgameUrl = useSelector(
    (state) => state?.gamesReducer?.playNowGame?.data
  );
  const handlePlayNow = async () => {
    saveActivity({
      EventPage: "Casino",
      EventType: "GameLaunch",
      attribute2: name || "",
    });
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: apiVars.partnerId,
        TimeZone: apiVars.timeZone,
        LanguageId: LanguageId,
        ProductId: productId,
        Method: "GetProductUrl",
        Controller: "Main",
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: "{}",
        IsForDemo: false,
        IsForMobile: isMobile ? true : false,
        Position: "",
        DeviceType: 1,
        ClientId: ClientId,
        Token: Token,
      };
      await playNowGame(payload);
    } else {
      await loginModal(true);
    }
  };

  const handlePlaybtn = async () => {
    if (closeHandler) closeHandler()
    if (Token) {
      setgamemodel(true);
      handlePlayNow();
    } else {
      await loginModal(true);
    }
  };
  return (
    <>
      {navbarcard ? (
        <button onClick={handlePlaybtn} className="gameCard">
          <div className="gameCard-img">
            <img
              width="130"
              height="90"
              loading="lazy"
              src={gameImage}
              alt={name}
            />
          </div>
          <p className="gameCard-name">{name}</p>
          <img
            loading="lazy"
            className="glow"
            src={Images.gameGlowDeco}
            alt="glow"
          />
        </button>
      ) : (
        <button
          onClick={props.handleClick}
          className={`${isRoundImage ? "is-round" : ""} gameCard`}
        >
          <div className="gameCard-img">
            <img
              width="281"
              height="281"
              loading="lazy"
              src={props.url}
              alt={t(props.keyName)}
            />
          </div>
          <p className="gameCard-name">
            {t(props.keyName)}
          </p>
          <img
            loading="lazy"
            className={`${themeMode === "light" ? "black-image" : ""} glow`}
            src={Images.gameGlowDeco}
            alt="glow"
          />
          {props.isTop10 && <p className="gameCard-num">{props.index + 1}</p>}
        </button>
      )}
      <CasinoCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        result={props}
        showgameUrl={showgameUrl}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isloggedIn: state.loginReducer?.isloggedIn,
  };
};

export default connect(mapStateToProps, {
  loginModal,
  playNowGame,
  checkLoginStatus,
})(GameCard);
