import browser from "browser-detect";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../Components/Common/getIp";
import BasicLayout from "../BasicLayout";
import { getItemFromLocalStorage } from "../utils/localStorage";
import CasherNav from "../modules/Casher/context/context";
import { historyNavEl } from "../modules/Casher/lib/constants";
import {
	getBetHistory,
	getPaymentHistory,
  getPaymentType,
  getProductOptions,
  getStatusOptions,
  getTransactionHistory,
  getTransactionTypes
} from "../modules/Casher/Services/actions";
import CabinetNav from "../Components/History/CabinetNav";
import CabinetNavComponents from "../Components/History/CabinetNavComponents";
import BetFilters from "../Components/History/BetFilters";
import MainHistory from "../Components/History/MainHistory";
import PaymentFilters from "../Components/History/PaymentFilters";
import TransactionFilters from "../Components/History/TransactionFilters";
import { apiVars } from "../constant";
const browserInfo = browser();

const History = (props) => {
  const { t } = useTranslation();
  const { histUrl, setHistUrl } = useContext(CasherNav);
  const { getStatusOptions, getProductOptions, getBetHistory, getTransactionTypes, getTransactionHistory, getPaymentType, getPaymentHistory } = props;

  const ProductOptions = useSelector(
    (state) => state?.cashierReducer?.productOptions?.data?.ResponseObject
  );
  const StatusOptions = useSelector(
    (state) => state?.cashierReducer?.statusOptions?.data?.ResponseObject
  );
  const TransectionOptions = useSelector(
    (state) => state?.cashierReducer?.transectionTypes?.data?.ResponseObject?.OperationTypes
  );
  const PaymentType = useSelector(
    (state) => state?.cashierReducer?.paymentType?.data?.ResponseObject
  );
  const Payhistory = useSelector(
    (state) => state?.cashierReducer?.paymentHistory?.data
  );
  const Bethistory = useSelector(
    (state) => state?.cashierReducer?.betHistory?.data
  );
  const Transactionhistory = useSelector(
    (state) => state?.cashierReducer?.transectionHistory?.data
  );

  const [selectedBetStatus, setSelectedBetStatus] = useState();
  const [selectedBetProducts, setSelectedBetProducts] = useState();
  const [betSelectValue, setbetSelectValue] = useState({
    time: "24 hours",
    status: "all",
    product: "",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  const [TypeOption, setTypeOption] = useState();
  const [transectionSelectValue, settransectionSelectValue] = useState({
    time: "24 hours",
    type: "all",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  const [paymentType, setpaymentType] = useState();
  const [SkipCount, setSkipCount] = useState(0);

  const [paymentSelectValue, setpaymentSelectValue] = useState({
    time: "24 hours",
    status: "all",
    type: "all",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let CurrencyId = getItemFromLocalStorage("CurrencyId");
  const LanguageId = getItemFromLocalStorage("i18nextLng");

  useEffect(() => {
    setSelectedBetStatus(StatusOptions);
    setSelectedBetProducts(ProductOptions);
    setTypeOption(TransectionOptions);
    setpaymentType(PaymentType);
  }, [ProductOptions, StatusOptions, TransectionOptions, PaymentType]);

  const handleStatusOptions = async () => {
    let payload = {
      ActivationKey: null,
      CategoryId: null,
      ClientId: ClientId,
      Code: null,
      Controller: "Document",
      Email: null,
      Index: null,
      LanguageId: LanguageId,
      Loader: true,
      Method: "GetBetStates",
      MobileNumber: null,
      PageIndex: 0,
      PageSize: 100,
      PartnerId: apiVars.partnerId,
      ProductId: null,
      ProviderIds: [],
      RequestData: "{}",
      TimeZone: apiVars.timeZone,
      Token: Token,
      PaymentInfo: null,
      Type: null
    };
    await getStatusOptions(payload);
  };

  const ProductStatusOptions = async () => {
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      Method: "GetProductGroups",
      Controller: "Product",
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      ClientId: ClientId,
      Token: Token,
    };
    await getProductOptions(payload);
  };

  const TransectionTypes = async () => {
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetOperationTypes",
      Controller: "Document",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getTransactionTypes(payload);
  };

  const PaymentTypes = async () => {
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetPaymentRequestTypes",
      Controller: "Document",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getPaymentType(payload);
  };

  useEffect(() => {
    if (histUrl === "Bets") {
      handleStatusOptions();
      ProductStatusOptions();
    } else if (histUrl === "Transactions") {
      TransectionTypes();
    } else {
      PaymentTypes();
    }
    // eslint-disable-next-line
  }, [histUrl]);

  useEffect(() => {
    handleBetHistory(histUrl);
    // eslint-disable-next-line
  }, [histUrl, SkipCount]);

  useEffect(() => {
    setSkipCount(0);
  }, [histUrl]);


  let commonPayload = {
    Loader: true,
    PartnerId: apiVars.partnerId,
    TimeZone: apiVars.timeZone,
    LanguageId: LanguageId,
    Controller: "Document",
    CategoryId: null,
    PageIndex: 0,
    PageSize: 100,
    ProviderIds: [],
    Index: null,
    ActivationKey: null,
    MobileNumber: null,
    Code: null,
  };

  const handleBetHistory = async (e) => {
    if (Token) {
      let deviceIp = await getDeviceIP();
      if (e === "Bets") {
        let payload = {
          ...commonPayload,
          Method: "GetBetsHistory",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: apiVars.partnerId,
            TimeZone: apiVars.timeZone,
            CreatedFrom: betSelectValue.fromDate,
            CreatedBefore: betSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            GroupId: betSelectValue.product,
            ProductIds: null,
            Status:
              betSelectValue.status === "all" ? null : betSelectValue.status,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getBetHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Bets",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: betSelectValue?.time || "all",
          attribute3: betSelectValue.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      } else if (e === "Transactions") {
        let payload = {
          ...commonPayload,
          Method: "GetTransactionHistory",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: apiVars.partnerId,
            TimeZone: apiVars.timeZone,
            CreatedFrom: transectionSelectValue.fromDate,
            CreatedBefore: transectionSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            OperationTypeId:
              transectionSelectValue.type === "all"
                ? null
                : transectionSelectValue.type,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getTransactionHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Transactions",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: transectionSelectValue?.time || "all",
          attribute3: transectionSelectValue.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      } else {
        let payload = {
          ...commonPayload,
          Method: "GetPaymentRequests",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: apiVars.partnerId,
            TimeZone: apiVars.timeZone,
            CreatedFrom: paymentSelectValue.fromDate,
            CreatedBefore: paymentSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            Statuses:
              paymentSelectValue.status === "all"
                ? null
                : paymentSelectValue.status,
            Type:
              paymentSelectValue.type === "all"
                ? null
                : paymentSelectValue.type,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getPaymentHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Payments",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: paymentSelectValue?.time || "all",
          attribute3: paymentSelectValue?.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
    }
  };

  const navElPayment = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "Type",
      key: "Type",
      render: (text) => <p>{text === 2 ? "Deposit" : "Withdraw"}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "StatusName",
      key: "StatusName",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("BetShop"),
      dataIndex: "BetShopId",
      key: "BetShopId",
      responsive: ["md"],
      render: (text) => <p>{text ? text : "NA"}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("Action"),
      dataIndex: "Action",
      key: "Action",
      responsive: ["md"],
      render: (text) => <p>{text ? text : "NA"}</p>,
    },
  ];

  const handleCheckStatus = (value) => {
    let status = selectedBetStatus?.filter((e) => e.Value === value);
    status = status?.length && status[0]?.Name;
    return status;
  };

  const navElBets = [
    {
      title: t("Id"),
      dataIndex: "BetDocumentId",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "BetDate",
      key: "Date",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "BetAmount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Product name"),
      dataIndex: "ProductName",
      key: "Product Name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Bet type"),
      dataIndex: "BetTypeId",
      key: "Bet Type",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "State",
      key: "Status",
      responsive: ["md"],
      render: (text) => <p>{handleCheckStatus(text)}</p>,
    },
    {
      title: t("Win amount"),
      dataIndex: "WinAmount",
      key: "Win Amount",
      render: (text) => <p>{text}</p>,
    },
  ];

  const navElTransaction = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "CreationTime",
      key: "date",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("BetId"),
      dataIndex: "DocumentId",
      key: "Bet Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "OperationTypeName",
      key: "Type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{`EUR ${text}`}</p>,
    },
  ];

  const main = () => {
    return (
      <>
        <div className="cabinet-subNav cabinet-subNav-history">
					{histUrl === "Bets" ? (
						<BetFilters
							setbetSelectValue={setbetSelectValue}
							betSelectValue={betSelectValue}
							selectedBetStatus={selectedBetStatus}
							selectedBetProducts={selectedBetProducts}
						/>
					) : histUrl === "Transactions" ? (
						<TransactionFilters
							settransectionSelectValue={settransectionSelectValue}
							transectionSelectValue={transectionSelectValue}
							TypeOption={TypeOption}
						/>
					) : (
						<PaymentFilters
							paymentType={paymentType}
							selectedBetStatus={selectedBetStatus}
							setpaymentSelectValue={setpaymentSelectValue}
							paymentSelectValue={paymentSelectValue}
						/>
					)}
					<div className="btn-block">
						<button type="button" className="theme-btn" data-e2e={t("Show")} onClick={() => handleBetHistory(histUrl)}>
							{t("Show")}
						</button>
					</div>
				</div>
				<div className="cabinet-divider"></div>
        {histUrl === "Bets" ? (
          <MainHistory
            navEl={navElBets}
            empty={t("Bets-empty")}
            Payhistory={Bethistory?.ResponseObject?.Bets}
            History={Bethistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        ) : histUrl === "Transactions" ? (
          <MainHistory
            navEl={navElTransaction}
            empty={t("Transactions-empty")}
            Payhistory={Transactionhistory?.ResponseObject?.Transactions}
            History={Transactionhistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        ) : (
          <MainHistory
            navEl={navElPayment}
            empty={t("Payments-empty")}
            Payhistory={Payhistory?.ResponseObject?.PaymentRequests}
            History={Payhistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        )}
      </>
    );
  };

  return (
    <BasicLayout>
			<div className="cabinet uw-content content-px content-py">
				<div className="uw-content">
					<CabinetNav />
					<div className="cabinet-block">
						<CabinetNavComponents navEl={historyNavEl} url={histUrl} setUrl={setHistUrl} />
						{main()}
					</div>
				</div>
			</div>
    </BasicLayout>
  );
};

export default connect(null, {
  getStatusOptions,
  getProductOptions,
  getBetHistory,
  getTransactionTypes,
  getTransactionHistory,
  getPaymentType,
  getPaymentHistory,
})(History);
