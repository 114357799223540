import { NavLink } from "react-router-dom";
import { useState } from "react";
import NavbarSearch from "./NavbarSearch";
import GameCard from "../Game/GameCard";
import { useSelector } from "react-redux";
// import SwitchTheme from "./SwitchTheme";
import LangSwitch from "../Menu/LangSwitch"
import { replaceGamesKeysNames } from "../../utils/helper";
import { sidebarRoutes } from "../../constant";
import { useTranslation } from "react-i18next";
import { routes } from "../../Route/URLs";
import { Images } from "../../Images";
import Icon from "../global/Icon";



export default function NavbarMainSidebar(props) {
  const { t } = useTranslation();
  const { closeHandler, isSidebar } = props;
  const [activeSearch, setActiveSearch] = useState(false);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  const gamesData = useSelector(
    (state) => state?.headerReducer?.searchbarGames
  );

  function inputClick() {
    setActiveSearch(true);
  }

  let allGames = gamesData?.Games;
  const gameElements = allGames
    ? allGames.map((game) => {
        let result = replaceGamesKeysNames(game);
        return <GameCard key={result.name} {...result} navbarcard={true} closeHandler={closeHandler}/>;
      })
    : [];

	const fastLinks = [
		{ keyName: "Casino", path: routes.CASINO },
		{ keyName: "Live Casino", path: routes.LIVE_CASINO },
		{ keyName: "Games", path: routes.GAMES },
	];

  const sidebarRoutesElements = sidebarRoutes.map((link, index) => {
    return (
      <NavLink key={`${link.key}-tablet`} to={link.url} className="sidebarM-link sidebarM-link-main">
        <div className="sidebarM-link-row">
					<Icon id={link.icon} />
          {t(link.name)}
        </div>
        <img className="sidebarM-link-chevron" width="16" height="16" src={Images.chevronRightIcon} alt="chevron right icon" />
      </NavLink>
    );
  });

  return (
    <div className={`${isSidebar ? "active" : ""} sidebarM sidebarM-main on-tablet`}>
      <div className="sidebar-between">
        <div>
					<div className="sidebarM-header">
						<div className="sidebarM-header-logo"><Icon id="mobileLogo" /></div>
						<button onClick={closeHandler} className="sidebarM-close">
							<Icon id="close" />
						</button>
					</div>
					<div className="sidebarM-content">
						<div className="sidebarM-search">
							<NavbarSearch
								isTablet={true}
								inputTabletClickHandler={inputClick}
							/>
						</div>
						{!activeSearch && (
							<div>
								<div className="sidebarM-links">
									{sidebarRoutesElements}
								</div>
								<div className="sidebarM-main-divider"></div>
								<div className="sidebarM-main-bottom">
									<div className="d-flex">
										<NavLink to="/support" className="sidebarM-support">
											<div className="sidebarM-support-icon">
												<Icon id="support" />
											</div>
											Support
										</NavLink>
									</div>
									<div className="d-flex">
										<LangSwitch isTablet={true} />
									</div>
								</div>	
								<br/>
							</div>
						)}	
						{activeSearch && (
							<div>
								<button
									onClick={(e) => {
										e.stopPropagation();
										setActiveSearch(false);
									}}
									className="sidebarM-back"
								>
									<img
										className="chevron-left"
										width="16"
										height="16"
										src={Images.chevronRightIcon}
										alt="chevron icon"
									/>
									Back
								</button>
								<p className="navbar-search-title">{t("Recommended")}</p>
								<div className="sidebarM-search-games">
									{gameElements.length > 0 ? gameElements : null}
								</div>
								<p className="navbar-search-title">{t("Fast links")}</p>
								<div className="navbar-search-links">
									{fastLinks.map((link) => {
										return (
											<NavLink
												to={link.path}
												key={`${link.name}-t`}
												className="navbar-search-link"
											>
												<img
													className={themeMode === "light" ? "black-image" : ""}
													width="25"
													height="14"
													src={Images.fastLinkIcon}
													alt="fast link icon"
												/>
												{t(link.keyName)}
											</NavLink>
										);
									})}
								</div>
								<br/>
							</div>
						)}
					</div>
        </div>
      </div>
    </div>
  );
}
