import React, { useContext } from "react";
import CasherNav from "../../modules/Casher/context/context";
import { profileNavEl } from "../../modules/Casher/lib/constants";
import CabinetNav from "../History/CabinetNavComponents";
import SettingsMain from "./SettingsMain";
const MainSection = (
  {
    editStatus,
    handleEditStatus,
    settingSelect,
    setSettingSelect,
    navMobile,
    setNavMobile,
  },
  props
) => {
  const { profileUrl, setProfileUrl } = useContext(CasherNav);

  const main = () => {
    if (profileUrl === "Settings")
      return (
        <SettingsMain
          editStatus={editStatus}
          handleEditStatus={handleEditStatus}
          settingSelect={settingSelect}
        />
      );
  };

  return (
    <div className="cabinet-block">
      <div className="">
        <CabinetNav
          navEl={profileNavEl}
          url={profileUrl}
          setUrl={setProfileUrl}
          navMobile={navMobile}
          setNavMobile={setNavMobile}
        />
			</div>
      {main()}
    </div>
  );
};

export default MainSection;
