import browser from "browser-detect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveActivity } from "../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../Common/getIp";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import TextField from '@mui/material/TextField';
import { getAllCities, getAllCountries } from "../../modules/Login/actions";
import { sendVerificationCode, updateProfileInfo } from "../../modules/Casher/Profile/Services/actions";
import { apiVars } from "../../constant";
import Autocomplete from '@mui/material/Autocomplete';
import CustomAlert from "../global/CustomAlert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const browserInfo = browser();

const SettingsMain = () => {
  const dispatch = useDispatch();
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const { t } = useTranslation();
  let userData = getItemFromLocalStorage("userData");
    const [dobError, setDobError] = useState(null);
	// eslint-disable-next-line
	const testEmailRule = (v) => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || false;

  let allCountriesData = useSelector(
    (state) => state?.loginReducer?.allCountries?.data?.ResponseObject
  );

  const [profileData, setProfileData] = useState({
    mobileNo: userData?.MobileNumber || "",
    email: userData?.Email || "",
    fName: userData?.FirstName || "",
    lName: userData?.LastName || "",
    BirthDate: userData?.BirthDate || "",
    address: userData?.Address || "",
    currency: userData?.CurrencyId || "",
    documentNo: userData?.DocumentNumber || "",
    documentIssuedBy: userData?.DocumentIssuedBy || "",
    ZipCode: userData?.ZipCode || "",
    CountryId: userData?.CountryId || "",
    city: userData?.City || "",
  });

  const handleChange = (e) => setProfileData({ ...profileData, [e.target.name]: e.target.value });
	const handleChangeCountry = (e, value) => {
		setProfileData({ ...profileData, CountryId: value?.Id || "" })
		if (value?.Id) {
			handleCities(value?.Id)
		}
	};

	// eslint-disable-next-line
  const handleSendVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: apiVars.partnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToMobileNumber",
      RequestData: JSON.stringify({
        MobileNumber: profileData?.mobileNo,
      }),
      TimeZone: apiVars.timeZone,
    };
    dispatch(sendVerificationCode(payload));
  };
	// eslint-disable-next-line
	const handleSendEmailVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: apiVars.partnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToEmail",
      RequestData: JSON.stringify({
        email: profileData?.email,
      }),
      TimeZone: apiVars.timeZone,
    };
    dispatch(sendVerificationCode(payload));
  };

  const handleUpdate = async () => {
		if (!testEmailRule(profileData.email)) return
    let payload = {
      Loader: true,
      PartnerId: apiVars.partnerId,
      TimeZone: apiVars.timeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "ChangeClientDetails",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: profileData?.mobileNo,
      Code: null,
      RequestData: JSON.stringify({
        ClientId: userData?.Id,
        Email: profileData?.email,
        IsEmailVerified: userData?.IsEmailVerified,
        CurrencyId: profileData?.currency,
        BirthDate: profileData?.BirthDate?.$d,
        FirstName: profileData?.fName,
        LastName: profileData?.lName,
        DocumentNumber: profileData?.documentNo,
        DocumentIssuedBy: profileData?.documentIssuedBy,
        Info: null,
        Address: profileData?.address,
        MobileNumber: profileData?.mobileNo,
        IsMobileNumberVerified: userData?.IsMobileNumberVerified,
        LanguageId: LanguageId,
        IsDocumentVerified: userData?.IsDocumentVerified,
        ZipCode: profileData?.ZipCode,
        EmailOrMobile: profileData?.email,
        Gender: userData?.Gender,
        CountryId: profileData?.CountryId,
        RegionId: userData?.RegionId,
        Citizenship: userData?.Citizenship,
        City: profileData?.city,
      }),
      ClientId: userData?.Id,
      Token: userData?.Token || localStorage.getItem("Token"),
    };

    if (dobError || !profileData?.BirthDate) return;
    dispatch(updateProfileInfo(payload));

    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Profile_SaveChanges",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };

  let payload = { LanguageId: "en", PartnerId: apiVars.partnerId, TimeZone: apiVars.timeZone };

  const onBirthdayChange = (value) => {
      setProfileData((prev) => {
          return {
              ...prev,
              BirthDate: value,
          };
      });
  };

  const handleCities = (value) => {
    setProfileData({...profileData, CountryId: value})
    dispatch(
      getAllCities({
        ...payload,
        ParentId: value,
        TypeId: 3,
      })
    );
  };

useEffect(() => {
	dispatch(getAllCountries({ ...payload, TypeId: 5 }));
	dispatch(
		getAllCities({
			...payload,
			ParentId: userData?.CountryId,
			TypeId: 3,
		})
	); // eslint-disable-next-line
}, []);

  return (
    <div className="profile-settings profile-pd">
      <p className="cabinetForm-title">{t("PersonalDetails")}</p>
      <div className="profile-settings-fields">
        <div className="inputGroup">
          <p className="inputGroup-label">{t("FirstName")}</p>
            <TextField
                className="theme-input"
                placeholder={t("Firstname-placeholder")}
                data-e2e="first-name"
                variant="outlined"
                name="fName"
                value={profileData.fName}
                onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("LastName")}</p>
            <TextField
                className="theme-input"
                placeholder={t("Lastname-placeholder")}
                data-e2e="last-name"
                variant="outlined"
                name="fNalNameme"
                value={profileData.lName}
                onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">
            {t("Date-of-birth")} {dobError && <span className="error-text">*Please, enter a valid Birthdate</span>}
            {!profileData?.BirthDate && <span className="error-text">*Birthdate value is required</span>}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                className="dobDatePicker"
                onChange={(date, dateString) => onBirthdayChange(date)}
                value={profileData?.BirthDate && dayjs(profileData?.BirthDate)}
                minDate={dayjs().subtract(99, "year")}
                maxDate={dayjs().subtract(18, "year")}
                views={["year", "month", "day"]}
                format="YYYY-MM-DD"
                onError={(newError) => setDobError(newError)}
            />
          </LocalizationProvider>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label" data-e2e={t("Currency")}>
            {t("Currency")}
          </p>
            <TextField
                className="theme-input"
                value="EUR"
                variant="outlined"
                name="fNalNameme"
                disabled
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Email")}</p>
          <div className="cabinet-blockBtn">
              <TextField
                className="theme-input"
                type="email"
                data-e2e="email"
                placeholder={t("Email-placeholder")}
                value={profileData.email}
                name="email"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
          </div>
            {!testEmailRule(profileData.email) && <CustomAlert severity="error" text="Please fill your email correctly, this field is required" />}
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("MobileNumber")}</p>
          <div className="cabinet-blockBtn">
              <TextField
                className="theme-input"
                data-e2e="mobile-number"
                placeholder={t("Mobile-number-placeholder")}
                value={profileData.mobileNo}
                name="mobileNo"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
          </div>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Document number")}</p>
            <TextField
                className="theme-input"
                data-e2e="document-number"
                placeholder={t("Document number")}
                value={profileData.documentNo}
                name="documentNo"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Document issued by")}</p>
            <TextField
                className="theme-input"
                placeholder={t("Text")}
                value={profileData.documentIssuedBy}
                name="documentIssuedBy"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">Country</p>
            <Autocomplete
                disablePortal
                data-e2e="country"
                options={allCountriesData || []}
                getOptionLabel={(option) => option.Name}
                renderInput={(params) => <TextField {...params} placeholder="Select a country" />}
                ListboxProps={{
                    style: { maxHeight: 150 },
                }}
                value={allCountriesData?.find(country => country.Id === profileData.CountryId) || null}
                onChange={(e, value) => handleChangeCountry(e, value)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("City")}</p>
          <TextField
              className="theme-input no-border"
              placeholder={t("City")}
              data-e2e="country"
              value={profileData.city}
              name="city"
              variant="outlined"
              onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Address")}</p>
            <TextField
                placeholder={t("Address-placeholder")}
                className="theme-input"
                data-e2e="address"
                value={profileData.address}
                name="address"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">ZipCode</p>
            <TextField
                placeholder="ZipCode"
                className="theme-input"
                data-e2e="ZipCode"
                value={profileData.ZipCode}
                name="ZipCode"
                variant="outlined"
                onChange={(e) => handleChange(e)}
            />
        </div>
      </div>
      <div className="profile-settings-btns">
        <button
          className="theme-btn"
          onClick={handleUpdate}
          data-e2e={t("Save-Changes")}
          type="button"
        >
          {t("Save-Changes")}
        </button>
        <button className="theme-btn-outlined" data-e2e={t("Cancel-changes")}>
          <span>{t("Cancel-changes")}</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsMain;
