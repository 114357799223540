import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Images } from "../../Images";
import { useTranslation } from "react-i18next";

export default function GameNavbarSearch({ searchText, setSearchText }) {
  const { t } = useTranslation();
  return (
    <div className="casino-nav-search">
      <TextField
        className="theme-input search-input"
        placeholder={t("Search_game")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                width="18"
                height="18"
                src={Images.searchIcon}
                alt="icon"
                loading="lazy"
              />
            </InputAdornment>
          ),
        }}
        value={searchText}
        variant="outlined"
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
}
