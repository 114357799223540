import browser from "browser-detect";
import React, { useEffect, useState } from "react";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../Components/Common/getIp";
import BasicLayout from "../BasicLayout";
import { getItemFromLocalStorage } from "../utils/localStorage";
import GameNavbar from "../Components/Games/GameNavbar";
import CasinoHeader from "../Components/Casino/CasinoHeader";
import { useLocation } from "react-router-dom";
const browserInfo = browser();

const CasinoPage = () => {
	const location = useLocation();
  const { pathname } = location;
  const [searchText, setSearchText] = useState("");
	
	useEffect(() => {
		let pageName = pathname.split("/")[1]
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetGames",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
    //eslint-disable-next-line
  }, []);

  return (
    <BasicLayout>
      <div className="casino-page">
        <CasinoHeader searchText={searchText} setSearchText={setSearchText} />
        <GameNavbar searchText={searchText} setSearchText={setSearchText} />
      </div>
    </BasicLayout>
  );
};

export default CasinoPage;
