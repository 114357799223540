import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { useHistory, Link } from "react-router-dom";
// import { Images } from "../../Images/index";
// import { routes } from "../../Route/URLs";
import { logout } from "../../modules/Login/actions";
import NavbarSearch from "./NavbarSearch";
import NavbarProfileMenu from "./NavbarProfileMenu";
import NavbarProfileTablet from "./NavbarProfileTablet";
import NavbarMainTablet from "./NavbarMainTablet";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { getClientBalanceSuccess } from "../../modules/Navbar/actions";
import axios from "axios";
import { apiVars } from "../../constant";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = (props) => {
  const { t } = useTranslation();
  const { logout, getClientBalanceSuccess } = props;
  const HandleLogout = () => {
    logout(false);
    window.location.href = "/login";
  };

  useEffect(() => {
    setIntervalCalls();
    let interval1 = setInterval(() => {
      setIntervalCalls();
    }, 5000);
    return () => {
      clearInterval(interval1);
    };
    // eslint-disable-next-line
  }, []);

  const setIntervalCalls = async () => {
    const PartnerId = getItemFromLocalStorage("PartnerId");
    const CurrencyId = getItemFromLocalStorage("CurrencyId");
    const ClientId = getItemFromLocalStorage("Id");
    const Token = getItemFromLocalStorage("Token");
    let payload = {
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
    };
    if (Token) {
      axios
        .post(`${apiVars.baseApiUrl}/Main/GetClientBalance`, payload)
        .then((res) => getClientBalanceSuccess(res))
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="main-navbar content-px">
        <NavbarMainTablet />
        <div className="navbar-search-d on-desktop">
          <NavbarSearch />
        </div>
        <div className="main-navbar-right">
          <NavLink
            className="theme-btn r-35 main-navbar-deposit"
            to="/deposit">
            {t("Deposit")}
          </NavLink>
          <NavbarProfileMenu logoutHandler={HandleLogout} />
          <NavbarProfileTablet logoutHandler={HandleLogout} />
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getClientBalanceSuccess,
  logout,
})(Navbar);
