export default function BannerLogoMobile() {
  return (
    <svg
      className="banner-logo mobile"
      width="195"
      height="137"
      viewBox="0 0 195 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M97.5001 1C71.9366 1 51.1365 21.8002 51.1365 47.3636C51.1365 72.9271 71.9366 93.7273 97.5001 93.7273C123.064 93.7273 143.864 72.9271 143.864 47.3636C143.864 21.8002 123.064 1 97.5001 1ZM137.613 47.3636C137.613 56.7445 134.379 65.3766 128.96 72.2199V22.5178C134.379 29.3611 137.613 37.9932 137.613 47.374V47.3636ZM124.27 17.5154L98.6233 43.162L71.9367 16.4753C78.8839 10.7137 87.7968 7.25046 97.5001 7.25046C107.775 7.25046 117.156 11.1401 124.26 17.5154H124.27ZM94.1929 47.5925L72.3007 69.4846V25.6898L94.1929 47.582V47.5925ZM57.3869 47.374C57.3869 37.9932 60.6213 29.3611 66.0398 22.5178V72.2303C60.6213 65.387 57.3869 56.7549 57.3869 47.374ZM72.1759 78.4599L122.7 27.9363V67.2694L107.193 51.7629L102.773 56.1829L124.031 77.4407C116.959 83.6912 107.661 87.4976 97.5001 87.4976C87.9008 87.4976 79.0919 84.1072 72.1759 78.4703V78.4599Z"
        stroke="url(#paint0_linear_3037_860)"
        strokeWidth="1.15909"
        strokeMiterlimit="10"
      />
      <path
        d="M0.136475 124.636C0.136475 122.901 0.46315 121.276 1.10094 119.775C1.73872 118.275 2.6254 116.962 3.76097 115.852C4.89654 114.727 6.2499 113.867 7.82103 113.226C9.39216 112.601 11.1344 112.273 13.0322 112.273C14.93 112.273 16.6412 112.585 18.2278 113.226C19.8145 113.851 21.1835 114.727 22.319 115.852C23.4546 116.962 24.3568 118.275 24.9946 119.775C25.6324 121.276 25.9591 122.886 25.9591 124.636C25.9591 126.387 25.6324 127.997 24.9946 129.497C24.3568 130.998 23.4702 132.311 22.319 133.421C21.1835 134.546 19.8145 135.406 18.2278 136.047C16.6412 136.672 14.9145 137 13.0322 137C11.15 137 9.40772 136.687 7.82103 136.047C6.2499 135.421 4.89654 134.546 3.76097 133.421C2.6254 132.311 1.73872 130.998 1.10094 129.497C0.46315 127.997 0.136475 126.387 0.136475 124.636ZM6.46769 124.636C6.46769 125.574 6.63879 126.434 6.99658 127.215C7.35436 127.997 7.82104 128.685 8.41216 129.263C9.00328 129.841 9.70328 130.295 10.4966 130.607C11.29 130.935 12.13 131.092 13.0167 131.092C13.9033 131.092 14.7434 130.935 15.5367 130.607C16.3301 130.295 17.03 129.841 17.6367 129.263C18.2434 128.685 18.7256 127.997 19.0679 127.215C19.4101 126.434 19.5968 125.574 19.5968 124.636C19.5968 123.699 19.4256 122.854 19.0679 122.057C18.7101 121.276 18.2434 120.588 17.6367 120.01C17.03 119.431 16.3301 118.978 15.5367 118.666C14.7434 118.353 13.9033 118.181 13.0167 118.181C12.13 118.181 11.29 118.337 10.4966 118.666C9.70328 118.994 9.00328 119.431 8.41216 120.01C7.82104 120.588 7.3388 121.276 6.99658 122.057C6.63879 122.839 6.46769 123.699 6.46769 124.636Z"
        fill="#DEBA70"
      />
      <path
        d="M35.9141 136.343V112.945H41.9653L53.1499 127.246V112.945H59.1699V136.343H53.1499L41.9653 122.041V136.343H35.9141Z"
        fill="#DEBA70"
      />
      <path
        d="M75.846 125.105L67.3525 112.945H74.586L78.9105 119.337L83.2039 112.945H90.4373L81.9127 125.105V136.343H75.8615V125.105H75.846Z"
        fill="#DEBA70"
      />
      <path
        d="M105.402 123.87L98.1375 112.945H105.604L108.747 118.994L112.636 112.945H120.102L112.418 123.87L121.098 136.343H113.724L109.058 128.716L103.598 136.343H96.2864L105.402 123.87Z"
        fill="#DEBA70"
      />
      <path
        d="M129.748 112.945H138.77C140.917 112.945 142.535 113.46 143.655 114.492C144.775 115.524 145.319 117.008 145.319 118.962C145.319 120.135 145.101 121.119 144.681 121.901C144.261 122.682 143.608 123.339 142.752 123.87C143.624 124.042 144.355 124.292 144.961 124.636C145.568 124.98 146.066 125.402 146.439 125.887C146.812 126.387 147.092 126.949 147.264 127.559C147.435 128.184 147.512 128.841 147.512 129.544C147.512 130.638 147.326 131.607 146.937 132.467C146.548 133.327 146.019 134.03 145.335 134.608C144.65 135.187 143.81 135.624 142.815 135.906C141.819 136.203 140.715 136.343 139.486 136.343H129.763V112.945H129.748ZM135.799 122.135H136.903C138.801 122.135 139.75 121.385 139.75 119.869C139.75 118.353 138.801 117.602 136.903 117.602H135.799V122.135ZM135.799 131.623H137.121C138.646 131.623 139.735 131.42 140.388 131.029C141.041 130.638 141.384 129.997 141.384 129.138C141.384 128.278 141.057 127.637 140.388 127.246C139.735 126.856 138.63 126.652 137.121 126.652H135.799V131.623Z"
        fill="#EEDCB7"
      />
      <path
        d="M170.535 118.087H163.333V121.995H170.131V127.153H163.333V131.185H170.535V136.343H157.281V112.945H170.535V118.103V118.087Z"
        fill="#EEDCB7"
      />
      <path
        d="M189.901 118.087V136.328H183.85V118.087H178.872V112.929H194.863V118.087H189.885H189.901Z"
        fill="#EEDCB7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3037_860"
          x1="50.6165"
          y1="47.374"
          x2="144.384"
          y2="47.374"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A1692A" />
          <stop
            offset="0.08"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.18"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.27"
            stopColor="#D3B15F"
          />
          <stop
            offset="0.34"
            stopColor="#F8EF9F"
          />
          <stop
            offset="0.41"
            stopColor="#FBF7C4"
          />
          <stop
            offset="0.48"
            stopColor="#F8EF9F"
          />
          <stop
            offset="0.59"
            stopColor="#D3B15F"
          />
          <stop
            offset="0.67"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.77"
            stopColor="#B07C31"
          />
          <stop
            offset="0.83"
            stopColor="#BA8230"
          />
          <stop
            offset="0.88"
            stopColor="#D4A245"
          />
          <stop
            offset="0.93"
            stopColor="#E0B353"
          />
          <stop
            offset="1"
            stopColor="#A36A2A"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
