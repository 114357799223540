import i18n from 'i18next';
import { Localisation } from "../../locale/Localisation";
import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { languageDropdown } from '../../constant';
import Icon from '../global/Icon';

export default function LangSwitch({isMobile}) {
  const { ChangeLanguage } = Localisation;
  const handleChangeLanguage = (e, val) => {
    ChangeLanguage(val);
		handleClose(e);
  };
	const activeLang = languageDropdown.find(x => x.key === i18n.language)

	useEffect(() => {
		if (activeLang.key === "he" || activeLang.key === "ar") {
			document.documentElement.setAttribute("dir", "rtl");
		} else {
			document.documentElement.setAttribute("dir", "ltr");
		}
	}, [activeLang]);

	const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
		event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation()
		setAnchorEl(null);

  };

  return (
		<div className="langSwitch">
			<button type="button" className={`langSwitch__btn ${open ? "active" : ""}`}
				aria-label="more"
        id="lang-button"
        aria-controls={open ? 'lang-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
			>
				<div className="langSwitch__btn-icon">
					<img src={activeLang.img} className="" alt="" />
				</div>
				{activeLang.value}
				<Icon id="chevronRight" />
			</button>
			<Menu
        id="lang-menu"
				className="langSwitch__menu custom-mui-select"
        MenuListProps={{
          'aria-labelledby': 'lang-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
          },
        }}
				transformOrigin={{ horizontal: !isMobile ? 'left' : "right", vertical: 'bottom' }}
        anchorOrigin={{ horizontal: !isMobile ? 'left' : "right", vertical: 'bottom' }}
      >
				{languageDropdown.map(x => {
					return (
						<MenuItem key={x.key} selected={x.key === activeLang.key} onClick={(e) => handleChangeLanguage(e, x.key)}>
							<img src={x.img} alt="" />
							{x.value}
						</MenuItem>
					)
				})}
      </Menu>
		</div>
  );
}
