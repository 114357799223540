import React from "react";
import BasicLayout from "../BasicLayout";
import { useTranslation } from "react-i18next";
import { Images } from "../Images";
import { NavLink } from "react-router-dom";
import Icon from "../Components/global/Icon";

export default function AboutUsPage() {
  const { t } = useTranslation();

  return (
    <BasicLayout>
      <div className="about">
        <div className="uw-content content-px content-py">
          <div
            className="about__banner"
            style={{ backgroundImage: `url(${Images.aboutBanner})` }}>
            <div className="about__banner-content">
              <div className="about__banner-title">{t("About us")}</div>
              <div className="about__banner-line"></div>
              <div
                className="about__banner-text"
                dangerouslySetInnerHTML={{ __html: t("about_text") }}></div>
              <div className="btn-block">
                <NavLink
                  to="/casino"
                  className="theme-btn">
                  {t("Play now")}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="about__ftr">
            <div className="about__ftr-grid">
              <div className="about__ftr-item">
                <div className="about__ftr-icon">
                  <img
                    src={Images.aboutDiscretion}
                    alt=""
                  />
                </div>
                <div className="about__ftr-item-title">{t("discretion")}</div>
                <div className="about__ftr-item-text">{t("discretion_text")}</div>
              </div>
              <div className="about__ftr-item">
                <div className="about__ftr-icon">
                  <img
                    src={Images.aboutMatches}
                    alt=""
                  />
                </div>
                <div className="about__ftr-item-title">{t("quality_games")}</div>
                <div className="about__ftr-item-text">{t("quality_games_text")}</div>
              </div>
              <div className="about__ftr-item">
                <div className="about__ftr-icon">
                  <img
                    src={Images.aboutGames}
                    alt=""
                  />
                </div>
                <div className="about__ftr-item-title">{t("super_casino")}</div>
                <div className="about__ftr-item-text">{t("super_casino_text")}</div>
              </div>
            </div>
          </div>
          <div className="casino-divider casino-divider-center"></div>
          <div className="about__support">
            <img
              className="about__support-mask"
              loading="lazy"
              src={Images.supportMask}
              alt=""
            />
            <div className="about__support-block">
              <div className="about__support-content">
                <img
                  className="about__support-logo"
                  src={Images.footerLogo}
                  alt="logo"
                />
                <p className="about__support-title about__ftr-item-title">{t("Support")}</p>
                <p className="about__support-text about__ftr-item-text">{t("support_text")}</p>
                <div className="btn-block">
                  <NavLink
                    to="/support"
                    className="theme-btn">
                    {t("Support")}
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="about__support-mobileLogo">
              <Icon id="mobileLogo" />
            </div>
            <img
              className="about__support-img"
              loading="lazy"
              src={Images.supportImg}
              alt=""
            />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
