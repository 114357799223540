import React from "react";
import FooterComponent from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import SidebarLeft from "../Components/Menu/SidebarLeft";
import { Images } from "../Images";

class OPLayout extends React.Component {
  renderPageHeader = () => {
    const { history } = this.props; 
    return <Navbar history={history} />;
  };

  renderContent = () => {
    const { children } = this.props;
    return (
			<div className="main-content" style={{backgroundImage: `url(${Images.textureBackground})`}}>
				<div className="main-content-children">{children}</div>
			</div>
    );
  };

  render() {
    return (
			<div className="layout">
				<SidebarLeft />
				<div className="layout-content">
					{this.renderPageHeader()}
					{this.renderContent()}
					<FooterComponent />
				</div>
      </div>
    );
  }
}

export default OPLayout;
