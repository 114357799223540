import moment from "moment";
import { useState, useEffect } from "react";
import Icon from "../global/Icon";

export default function NavbarTime() {
  const [Time, setTime] = useState("");

  const time = moment().format("HH:mm");
  let timeNow = `${time}`;
	useEffect(() => {
		const intervalId = setInterval(() => {
			const time = moment().format("HH:mm");
			setTime(time);
		}, 1000);
	
		return () => {
			clearInterval(intervalId); 
		};
	}, []);

  return (
    <div className="sidebar-time">
			<Icon id="navbarTime" />
      <p>{Time || timeNow}</p>
    </div>
  );
}
