import useOutsideClick from "../../helpers/outsideClick";
import { useRef, useState } from "react";
import NavbarMainSidebar from "./NavbarMainSidebar";
import Icon from "../global/Icon";
import { NavLink } from "react-router-dom";
// import { useHistory } from "react-router-dom";

export default function NavbarMainTablet() {
  const [isSidebar, setIsSidebar] = useState(false);
  // const history = useHistory();

  function toggleSidebar(bool) {
    setIsSidebar(bool);
  }

  const refSidebarMain = useRef();
  useOutsideClick(refSidebarMain, () => {
    setIsSidebar(false);
  });

  return (
    <div ref={refSidebarMain} className="main-navbar-left on-tablet">
			<button onClick={() => toggleSidebar(true)} className="navbar-mobileBtn">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M3 8.5H21M3 15.5H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
      </button>
      <div className="divider"></div>
      <NavLink to="/" className="navbar-logo-t navbar-logo-t-header cursor-pointer">
        <Icon id="mobileLogo" />
      </NavLink>
      <NavbarMainSidebar
        isSidebar={isSidebar}
        closeHandler={() => toggleSidebar(false)}
      />
    </div>
  );
}
