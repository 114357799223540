import { getItemFromLocalStorage } from "../../utils/localStorage";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import React, { useState, useRef, useEffect } from "react";
import NavbarProfileSidebar from "./NavbarProfileSidebar";
import useOutsideClick from "../../helpers/outsideClick";
import ProfileIcon from "../icons/ProfileIcon";

export default function NavbarProfileTablet(props) {
  const [userbalance, setuserbalance] = useState(0);
  const [isSidebar, setIsSidebar] = useState(false);

  const firstName = getItemFromLocalStorage("FirstName");
  const lastName = getItemFromLocalStorage("LastName");
  // const userName = firstName ? `${firstName[0]} ${lastName[0]}` : "N/A";
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  const isClientBalance = useSelector((state) => state?.headerReducer?.isClientBalance);
  const AvailableBalance = useSelector((state) => state?.headerReducer?.getClientBalanceData?.data?.AvailableBalance);

  function toggleSidebar(bool) {
    setIsSidebar(bool);
  }

  const refSidebarProfile = useRef();
  useOutsideClick(refSidebarProfile, () => {
    setIsSidebar(false);
  });

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance);
    }
  }, [AvailableBalance, isClientBalance]);

  return (
    <div className="navbar-profile-t on-tablet">
      <div className="navbar-profile-t-balance">
        {getSymbolFromCurrency[CurrencyId] || "€"} {userbalance || 0}
      </div>
      <div ref={refSidebarProfile}>
        <button
          type="button"
          className="navbar-profile-btn"
          onClick={() => toggleSidebar(true)}>
          <ProfileIcon />
          {/* <img
            width="32"
            height="32"
            src={Images.profileIcon}
            alt="logo"
          /> */}
        </button>
        <NavbarProfileSidebar
          isSidebar={isSidebar}
          logoutHandler={props.logoutHandler}
          closeHandler={() => toggleSidebar(false)}
          userbalance={userbalance}
          user={{
            firstName,
            lastName,
            AvailableBalance,
            currency: getSymbolFromCurrency[CurrencyId] || "€",
          }}
        />
      </div>
    </div>
  );
}
