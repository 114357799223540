import CabinetNavComponents from "../Components/History/CabinetNavComponents";
import CasherNav from "../modules/Casher/context/context";
import { useContext, useState } from "react";
import { ticketNavEl } from "../modules/Casher/lib/constants";
import MainHistory from "../Components/History/MainHistory";
import NewTicket from "../Components/Models/Mail/NewTicket";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import BasicLayout from "../BasicLayout";
import CabinetNav from "../Components/History/CabinetNav";
const TicketPage = () => {
  const { t } = useTranslation();
  const { ticketUrl, setTicketUrl } = useContext(CasherNav);
  const [mailmodel, setmailmodel] = useState(false);

  const TicketHistory = useSelector(
    (state) =>
      state?.cashierReducer?.ticketHistory?.data?.ResponseObject?.Tickets
  );

  const navElTicket = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "Status",
      key: "Status",
      render: (text) => <p>{text === 1 ? t("Active") : t("Closed")}</p>,
    },
    {
      title: t("Subject"),
      dataIndex: "Subject",
      key: "Subject",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Creation Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      render: (text) => <p>{moment(text).format("LL")}</p>,
      responsive: ["sm"],
    },
  ];

  return (
		<BasicLayout>
			<div className="uw-content content-px content-py">
				<CabinetNav />
				<div className="cabinet-block">
					<CabinetNavComponents navEl={ticketNavEl} url={ticketUrl} setUrl={setTicketUrl} />
					<div className="bankAccounts-add">
						<button onClick={() => setmailmodel(true)} className="theme-btn h-40">
							+ {t("New ticket")}
						</button>
					</div>
					<div className="cabinet-divider"></div>
					<MainHistory
						empty={t("Tickets-empty")}
						img="ticket"
						navEl={navElTicket}
						Payhistory={TicketHistory}
					/>
				</div>
				<NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
			</div>
		</BasicLayout>
  );
};

export default TicketPage;
