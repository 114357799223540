export default function BannerLogoDesktop() {
  return (
    <svg
      className="banner-logo desktop"
      width="424"
      height="130"
      viewBox="0 0 424 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65 1C29.7124 1 1 29.7124 1 65C1 100.288 29.7124 129 65 129C100.288 129 129 100.288 129 65C129 29.7124 100.288 1 65 1ZM120.372 65C120.372 77.9493 115.907 89.865 108.428 99.3114V30.703C115.907 40.1494 120.372 52.0651 120.372 65.0144V65ZM101.953 23.7977L66.5505 59.2001L29.7124 22.362C39.3024 14.4087 51.6056 9.62809 65 9.62809C79.1839 9.62809 92.1332 14.9973 101.939 23.7977H101.953ZM60.4347 65.3159L30.2149 95.5357V35.0816L60.4347 65.3015V65.3159ZM9.62808 65.0144C9.62808 52.0651 14.0928 40.1494 21.5724 30.703V99.3257C14.0928 89.8793 9.62808 77.9637 9.62808 65.0144ZM30.0426 107.925L99.7851 38.1826V92.4778L78.38 71.0727L72.2786 77.1741L101.623 106.518C91.8605 115.146 79.026 120.401 65 120.401C51.7492 120.401 39.5895 115.721 30.0426 107.939V107.925Z"
        stroke="url(#paint0_linear_2958_537)"
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M154.6 64.9999C154.6 62.605 155.051 60.361 155.931 58.2897C156.812 56.2184 158.036 54.4061 159.603 52.8742C161.171 51.3207 163.039 50.134 165.208 49.2494C167.377 48.3863 169.782 47.9332 172.401 47.9332C175.021 47.9332 177.383 48.3647 179.573 49.2494C181.764 50.1124 183.653 51.3207 185.221 52.8742C186.788 54.4061 188.034 56.2184 188.914 58.2897C189.794 60.361 190.245 62.5834 190.245 64.9999C190.245 67.4164 189.794 69.6387 188.914 71.71C188.034 73.7813 186.81 75.5938 185.221 77.1257C183.653 78.6791 181.764 79.8658 179.573 80.7504C177.383 81.6135 175 82.0666 172.401 82.0666C169.803 82.0666 167.398 81.635 165.208 80.7504C163.039 79.8874 161.171 78.6791 159.603 77.1257C158.036 75.5938 156.812 73.7813 155.931 71.71C155.051 69.6387 154.6 67.4164 154.6 64.9999ZM163.34 64.9999C163.34 66.2945 163.576 67.4812 164.07 68.56C164.564 69.6388 165.208 70.5881 166.024 71.3864C166.84 72.1847 167.806 72.8104 168.901 73.242C169.996 73.6951 171.156 73.9108 172.38 73.9108C173.604 73.9108 174.763 73.6951 175.858 73.242C176.954 72.8104 177.92 72.1847 178.757 71.3864C179.595 70.5881 180.26 69.6388 180.733 68.56C181.205 67.4812 181.463 66.2945 181.463 64.9999C181.463 63.7053 181.227 62.5402 180.733 61.4399C180.239 60.3611 179.595 59.4117 178.757 58.6134C177.92 57.8151 176.954 57.1894 175.858 56.7579C174.763 56.3263 173.604 56.089 172.38 56.089C171.156 56.089 169.996 56.3048 168.901 56.7579C167.806 57.211 166.84 57.8151 166.024 58.6134C165.208 59.4117 164.542 60.3611 164.07 61.4399C163.576 62.5187 163.34 63.7053 163.34 64.9999Z"
        fill="#DEBA70"
      />
      <path
        d="M203.988 81.1606V48.8612H212.341L227.78 68.6033V48.8612H236.09V81.1606H227.78L212.341 61.4185V81.1606H203.988Z"
        fill="#DEBA70"
      />
      <path
        d="M259.11 65.6474L247.385 48.8612H257.37L263.34 57.6858L269.266 48.8612H279.251L267.484 65.6474V81.1606H259.131V65.6474H259.11Z"
        fill="#DEBA70"
      />
      <path
        d="M299.908 63.9429L289.88 48.8612H300.188L304.525 57.2111L309.893 48.8612H320.201L309.593 63.9429L321.575 81.1606H311.397L304.955 70.6314L297.418 81.1606H287.325L299.908 63.9429Z"
        fill="#DEBA70"
      />
      <path
        d="M333.515 48.8612H345.969C348.932 48.8612 351.166 49.5732 352.712 50.9972C354.258 52.4213 355.009 54.471 355.009 57.168C355.009 58.7862 354.709 60.1455 354.129 61.2243C353.549 62.3031 352.647 63.2093 351.466 63.9429C352.669 64.1802 353.678 64.5254 354.515 65.0001C355.353 65.4748 356.04 66.0573 356.555 66.7262C357.071 67.4166 357.457 68.1934 357.693 69.0348C357.93 69.8979 358.037 70.804 358.037 71.775C358.037 73.2853 357.779 74.623 357.242 75.8097C356.706 76.9964 355.975 77.9673 355.031 78.7656C354.086 79.5639 352.926 80.1681 351.552 80.5564C350.178 80.9664 348.653 81.1606 346.957 81.1606H333.536V48.8612H333.515ZM341.868 61.5479H343.392C346.012 61.5479 347.322 60.5123 347.322 58.4194C347.322 56.3265 346.012 55.2909 343.392 55.2909H341.868V61.5479ZM341.868 74.6446H343.693C345.797 74.6446 347.3 74.3641 348.202 73.8247C349.104 73.2853 349.577 72.4007 349.577 71.214C349.577 70.0273 349.126 69.1427 348.202 68.6033C347.3 68.0639 345.776 67.7834 343.693 67.7834H341.868V74.6446Z"
        fill="#EEDCB7"
      />
      <path
        d="M389.817 55.9597H379.875V61.3537H389.258V68.4738H379.875V74.0405H389.817V81.1606H371.521V48.8612H389.817V55.9813V55.9597Z"
        fill="#EEDCB7"
      />
      <path
        d="M416.551 55.9594V81.1387H408.198V55.9594H401.326V48.8394H423.4V55.9594H416.529H416.551Z"
        fill="#EEDCB7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2958_537"
          x1="0.282189"
          y1="65.0144"
          x2="129.718"
          y2="65.0144"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A1692A" />
          <stop
            offset="0.08"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.18"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.27"
            stopColor="#D3B15F"
          />
          <stop
            offset="0.34"
            stopColor="#F8EF9F"
          />
          <stop
            offset="0.41"
            stopColor="#FBF7C4"
          />
          <stop
            offset="0.48"
            stopColor="#F8EF9F"
          />
          <stop
            offset="0.59"
            stopColor="#D3B15F"
          />
          <stop
            offset="0.67"
            stopColor="#BC8B3A"
          />
          <stop
            offset="0.77"
            stopColor="#B07C31"
          />
          <stop
            offset="0.83"
            stopColor="#BA8230"
          />
          <stop
            offset="0.88"
            stopColor="#D4A245"
          />
          <stop
            offset="0.93"
            stopColor="#E0B353"
          />
          <stop
            offset="1"
            stopColor="#A36A2A"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
