import React from "react";

export default function AuthBtn({type, disabled, loading, onClickCall, text, dataE2e, tabIndex}) {
	return (
		<button 
			{...(onClickCall && { onClick: onClickCall })} 
			type={!type ? "button" : type} 
			disabled={loading || disabled} 
			className="authPage__btn"
			{...(dataE2e && { "data-e2e": dataE2e })}
			{...(tabIndex && { "tabindex": tabIndex })}
		>
			{loading && <div className="authPage__btn-loader"></div>}
			{!loading && <span>{text}</span>}
		</button>
	)
}