import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sidebarRoutes } from "../../constant";
import LangSwitch from "./LangSwitch";
import Icon from "../global/Icon";

export default function SidebarMenuLinks() {
  const { t } = useTranslation();

  async function onMenuItemSelected(e) {
		console.log(e.key)
    // saveActivity({
    //   EventPage: "HomePage",
    //   EventType: "MenuClick",
    //   attribute1: getItemFromLocalStorage("UserName") || "",
    //   attribute2: e?.key?.split("/")[2] || "",
    // });
  }

  const sidebarRoutesElements = sidebarRoutes.map((link, index) => {
		return (
			<div key={link.url} onClick={(e) => onMenuItemSelected(e)} className="sidebar-link-btn">
				<NavLink to={link.url} className="sidebar-link">
					<div className="sidebar-link-icon">
						<Icon id={link.icon} />
						{/* <img src={link.icon} alt="icon" /> */}
					</div>
					<span>{t(link.name)}</span>
				</NavLink>
			</div>
		);
	});

  return (
    <div>
      {/* <NavLink onClick={(e) => this.onMenuItemSelected(e)} to={promotionRoute.url} key={promotionRoute.key} className="sidebar-link block-fill sidebar-link-promotions">
				<div className="sidebar-link-icon">
					<img src={promotionRoute.icon} alt="icon" />
				</div>
				<span>{promotionRoute.name}</span>
			</NavLink> */}
      <div className="sidebar-block block-fill">
        {sidebarRoutesElements}
      </div>
			<div className="sidebar-block block-bottom block-fill">
				<NavLink to="/support" className="sidebar-link">
          <div className="sidebar-link-icon support-icon">
            <Icon id="support" />
          </div>
          <span>{t("Support")}</span>
        </NavLink>
				<LangSwitch isMobile={true} />
      </div>
    </div>
  );
}
